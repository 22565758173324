import axios from "axios";

const API_URL = "https://oavlite.com/api1/api/auth/";
// const API_URL = "	http://localhost:8080/api/auth/";

const register = (formData) => {
  return axios
    .post(API_URL + "register", formData)
    .then((response) => {
      return response.data;
    });
};

const login = (email, password) => {
  return axios
    .post(API_URL + "login", {
      email,
      password,
    })
    .then((response) => {
      return response.data;
    });
};

const adminLogin = (email, password) => {
  return axios
    .post(API_URL + "admin-login", {
      email,
      password,
    })
    .then((response) => {
      return response.data;
    });
};

const reset_password = (email) => {
  console.log(email)
  return axios
    .post(API_URL + "reset-password", {
      email,
    })
    .then((response) => {
      return response.data;
    });
};

const sendCode = (email) => {
  return axios
    .post(API_URL + "sendCode", {
      email
    })
    .then((response) => {
      return response.data;
    });
};

const cancelSubscription = (subscriptionId) => {
  return axios
    .post(API_URL + "cancelSubscription", {
      subscriptionId
    })
    .then((response) => {
      return response.data;
    });
}

const getFriends = (data) => {
  return axios
    .post(API_URL + "get-friends", data)
    .then((response) => {
      return response.data;
    });
}

const logout = () => {
  localStorage.removeItem("user");
};

export default {
  register,
  login,
  adminLogin,
  sendCode,
  logout,
  reset_password,
  getFriends,
  cancelSubscription
};
