import axios from "axios";
import authHeader from "./auth-header";
const API_URL = "https://oavlite.com/api1/api/auth/";
// const API_URL = "	http://localhost:8080/api/store/";

const DeleteAdvice = (data) => {
  return axios.post(API_URL + "delete", data ,{ headers: authHeader() })
    .then(async (response) => {
      const { data } = response.data;
      return data;
    })
}


const GetAdvices = (data) => {
  return axios.post(API_URL + "get", data,{ headers: authHeader() })
    .then(async (response) => {
      const { data } = response.data;
      console.log(response)
      return response;
    })
}


const CreateAdvice = (data) => {
  return axios.post(API_URL + "create", data, { headers: authHeader() })
    .then(async (response) => {
      const { data } = response.data;
      return data;
    })
}

export default {
  CreateAdvice,
  GetAdvices,
  DeleteAdvice,
};
