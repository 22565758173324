
import { useNavigate } from 'react-router-dom';
import Logo from '../../assets/svgs/Logo.png';
import './styles.css';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { getUserSession, updateActive, updateProfile } from '../../actions/users';
import { EnCountries } from '../../constants/en';
import { FrCountries } from '../../constants/fr';

function Dashboard() {
    const navigate = useNavigate();
    const { customers } = useSelector(state => state.customers);
    const { t, i18n } = useTranslation();
    const dispatch = useDispatch();
    const [isEnglish, setIsEnglish] = useState(i18n.resolvedLanguage == 'en' ? true : false);

    useEffect(() => {
        dispatch(getUserSession({ country: "0" }))
    }, [])

    const onClickCountry = (country) => {
        dispatch(getUserSession({ country }))
    }

    const onChangeActive = (email, active) => {
        dispatch(updateActive({ email, active }))
    }
    return (
        <div className="content dashboard" style={{display:'block',minHeight:`${document.documentElement.clientHeight - 286}px`}}>

            {/* <div className='d-flex mt-1' style={{ justifyContent: "end" }}>
                <select style={{ width: "200px" }} onChange={(e) => onClickCountry(e.target.value)} className='select-region'>
                    {isEnglish ?
                        EnCountries && EnCountries.map((item, index) => <option value={item.id} key={item + index}>{item.name}</option>)
                        :
                        FrCountries && FrCountries.map((item, index) => <option value={item.id} key={item + index}>{item.name}</option>)
                    }
                </select>
            </div> */}
            <div style={{ display: 'flex', flexDirection: 'column', width: '100%', alignItems: 'center', marginTop: '100px' }}>
                <div style={{ width: '300px', marginBottom:'30px', padding: '10px 50px', fontSize: '14px', color: 'black', backgroundColor: 'black', borderRadius: '30px', color: 'white', textAlign: 'center' }}>
                    Total
                </div>
                <div style={{display:'flex', alignItems:'center', flexDirection:'column'}}>
                    <p>{t('Dashboard.UserRegister')}</p>
                    <p>{customers?.registeredCountAll}</p>
                </div>
                <div style={{display:'flex', alignItems:'center', flexDirection:'column'}}>
                    <p>{t('Dashboard.SubscriptionUser')}</p>
                    <p>{customers?.subscribedUserCount}</p>
                </div>
                <div style={{display:'flex', alignItems:'center', flexDirection:'column'}}>
                    <p>{t('Dashboard.SubscriberUserToday')}</p>
                    <p>{customers?.subscribedUserToday}</p>
                </div>
                <div style={{display:'flex', alignItems:'center', flexDirection:'column'}}>
                    <p>NUMBER OF RESEARCHES SENT TOTAL</p>
                    <p>{customers?.emailSentTotal}</p>
                </div>
            </div>
            <div className='mt-4 sub-info' style={{ justifyContent: "center", textAlign: "center", display: 'flex' }}>
                {/* <div>
                    <p>{t('Dashboard.UserRegisteredToday')}</p>
                    <p>{customers?.todayRegisteredCount}</p>
                </div> */}

            </div>
            {/* {customers?.allUsers && customers?.allUsers.map((item, index) => {
                return (
                    <div className='row mt-3' key={index}>
                        <div style={{ width: "20%", display: 'flex', justifyContent: 'center' }}>
                            <div className='photo'>
                                <img src={item.image_http} style={{ borderRadius: 15 }} />
                            </div>
                        </div>
                        <div style={{ width: "20%", textAlign: "center" }}>
                            <p>{item.username}</p>
                            <p className='p-green' style={{ marginBottom: "5px", marginTop: "15%" }}>REGISTERED ON 12/26/2023</p>
                            {item.active == 1 &&
                                <button onClick={() => onChangeActive(item.email, 0)} className='btn-green' style={{ fontWeight: "bold", paddingRight: "20px", paddingLeft: "20px" }}>BLOCK ACCOUNT</button>
                            }
                            {item.active == 0 &&
                                <button onClick={() => onChangeActive(item.email, 1)} style={{ fontWeight: "bold", paddingRight: "20px", paddingLeft: "20px", backgroundColor: '#efad27' }}>ACCOUNT BLOCKED</button>
                            }
                        </div>
                        <div style={{ width: "20%", textAlign: "center" }}>
                            <p>{item.firstname}</p>
                        </div>
                        <div style={{ width: "20%", textAlign: "center" }}>
                            <p>{item.email}</p>
                        </div>
                        <div style={{ width: "20%", textAlign: "center" }}>
                            <p>{t('Dashboard.Subscription')}</p>
                            {item.isExpired ?
                                <p className='p-yellow'>{t('NonValidate')}</p>
                                :
                                <p className='p-green'>{t('Validate')}</p>
                            }
                        </div>
                    </div>
                )
            })} */}
        </div>
    )
}

export default Dashboard;
