
import { useNavigate, useParams } from 'react-router-dom';
import Logo from '../../assets/svgs/Logo.png';
import './styles.css';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { canCelSubscription, createSubscription, updateExpiredAt, updateProfile } from '../../actions/users';
import { setMessage } from '../../actions/message';

function CancelSubscription() {
    const navigate = useNavigate();
    const { isLoggedIn, user } = useSelector(state => state.auth);
    const { t, i18n } = useTranslation();
    const dispatch = useDispatch();
    const handleCancelSubscription = () => {
        dispatch(canCelSubscription({id: user.user._id}))
    }
    useEffect(() => {

    }, [])

    return (
        <>
            <div className="content" style={{ display:'block',minHeight:'calc(100vh - 285px)' }}>
                <div style={{ justifyContent: "center", alignItems: "center", display: "flex", flexDirection: 'column', justifyContent: 'center' }}>
                    <label style={{ marginTop: "160px", marginBottom: "20px", fontSize: '24px', fontWeight:'bold' }}>WILL TAKE EFFECT THE MONTH FOLLOWING SUBSCRIPTION TERMINATION</label>
                    <button className='black-btn' style={{ border: "none", padding: '30px 60px', fontSize: '22px', fontWeight: 'bold', }} onClick={handleCancelSubscription}>
                        CANCEL SUBSCRIPTION
                    </button>
                </div>
            </div >
        </>
    )
}

export default CancelSubscription;
