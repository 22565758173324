import React, { useEffect, useState } from 'react'
import { useLocation } from 'react-router-dom'
import { useSelector } from 'react-redux'
import './index.css'
const Footer = () => {
    const location = useLocation();
    const { isLoggedIn, user } = useSelector(state => state.auth);

    const [isLarge, setLarge] = useState(false);
    useEffect(() => {
        console.log(location.pathname)
        if (location.pathname == "/new-store" || location.pathname.includes("/view-store"))
            setLarge(true)
        else
            setLarge(false)
    }, [location])

    if (location.pathname == "/home" && isLoggedIn)
        return;
    return (
        <div className='footer-container'>
            {
                isLarge && <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                    <div style={{ background: 'black', borderRadius: '30px', fontSize: '18px', padding: '3px 20px', textAlign: 'center', color: 'white', width: '280px' }}>CONTACT SELLER</div>
                    <p style={{ fontWeight: 'bold', margin: '3px 0px' }}>SELLER EMAIL</p>
                    <div style={{ border: '1px solid black', borderRadius: '30px', width: '400px', textAlign: 'center', color: 'black' }}>
                        synergis.agency.pro@gmail.com
                    </div>
                    <p style={{ fontSize: '15px', fontWeight: 'bold', margin: '0', marginTop: '1rem' }}>
                        THE DANCES ADVICES FOR 70$ ONLY SKETCHES DRAWING FOR YOUR ALL SELLABLE DANCES ADVICES NO TEXTS FOR FREELANCES SERVICES PRICE DEFINED BY SELLER
                    </p>
                    <p style={{ fontSize: '10px' }}>
                        Obligatory 7 sketches drawing minimum for your advice for a better understanding the seller cannot add less than the minimum which degrades the quality of their advice sold without 7 sketches drawing minimum may be the subject of a request for reimbursement
                    </p>
                </div>
            }
            <label style={{ textAlign: 'center', fontWeight: 'bold' }}>GIVEPARK copyright 2024</label>
            <a style={{ textAlign: 'center', textDecoration: 'none', color: 'black', fontWeight: 'bold' }} href='/terms'>General Terms and Conditions</a>
        </div>
    )
}

export default Footer;