import axios from 'axios'
import React, { useEffect, useState } from 'react'
import { useNavigate, useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import Logo from '../../assets/svgs/Logo.png';
import { logout } from '../../actions/auth';
import './index.css'
const API_URL = "http://localhost:8080/api/user/";

const Header = () => {
    const dispatch = useDispatch();
    const location = useLocation();
    const navigate = useNavigate();
    const { t, i18n } = useTranslation();
    const { isLoggedIn, user } = useSelector(state => state.auth);
    const [isStore, setStore] = useState(false);
    const Logout = () => {
        dispatch(logout());
    }

    useEffect(() => {
        console.log(location)
        if (location.pathname == '/new-store') {

        }
        else {

        }
    }, [location.pathname])

    const onClickBack = () => {
        if (location.pathname == '/checkout')
            navigate('/home')
        else
            navigate('/home')
    }

    const checkSubscription = () => {
        axios.post(API_URL + "check-subscription")
            .then((response) => {
                const { data } = response.data;
                if (data.access_token) {
                    localStorage.setItem("user", JSON.stringify(data));
                }
                return response.data;
            });
    }

    const handleClickRight = () => {
        if (location.pathname == '/new-store')
            window.location.href = "/cancel-subscription"
        else
            window.location.href = "/update-profile"
    }
    return (
        <div className="row" style={{ paddingTop: '60px', height: '186px' }}>
            <div className="col-md-3" style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', height: 'fit-content' }}>
                {/* {isLoggedIn && user?.user.role == '1' && <button style={{ width: "100px" }} onClick={Logout} className='btn-green'>{t('Home.Exit')}</button>} */}
                {user?.user.role !== "0" && <button onClick={() => { onClickBack() }} className='btn-back'>{t('Back')}</button>}
            </div>
            <div className="col-md-6" style={{ height: 'fit-content' }}>
                <div className='row main-logo' style={{ height: 'fit-content' }}>
                    <img src={Logo} alt="" onClick={() => { checkSubscription() }} />
                    {isLoggedIn ?
                        <p className='title' style={{ textAlign: 'center', fontSize: '26px', fontWeight: 'bold', color: 'black' }}>{t('Home.GiveToWorld')}</p>
                        :
                        <p className='title' style={{ textAlign: 'center', fontSize: '26px', fontWeight: 'bold', color: 'black' }}>{t('Home.Title')}</p>
                    }
                </div>
            </div>

            {user?.user.role == "0" ? <div className='col-md-3'></div>
                :
                <div className="col-md-3" style={{ paddingTop: '20px', height: 'fit-content' }}>
                    {isLoggedIn && user?.user.role == '1'
                        ?
                        <>
                            <div className='row' style={{ justifyContent: 'center' }}>
                                <button onClick={() => { handleClickRight() }} className='btn-green' style={{ width: '70%' }}>{location.pathname == '/new-store' ? "CANCEL MY SUBSCRIPTION" : t('Home.Account')}</button>
                            </div>
                        </>
                        :
                        <>
                            <div className='row' style={{ justifyContent: 'center' }}>
                                <button className='btn-green' style={{ width: "60%" }} onClick={() => { window.location.href = "/register" }} >{t('Home.REGISTRATION')}</button>
                            </div>
                            <div className='row' style={{ marginTop: '10px', justifyContent: 'center' }}>
                                <button className='btn-green' style={{ width: "60%" }} onClick={() => { window.location.href = "/login" }}>{t('Home.LOGIN')}</button>
                            </div>
                        </>
                    }
                </div>}
        </div>
    )
}

export default Header;