import React, { useEffect } from 'react';
import { Navigate, Routes, Route } from "react-router-dom";
import Home from './pages/Home/home';
import Login from './pages/Login/login';
import Register from './pages/Register/register';
import UpdateProfile from './pages/UpdateProfile/updateprofile';
import Subscription from './pages/Subscription/subscription';
import Dashboard from './pages/Dashboard/dashboard';
import NewStore from './pages/NewStore';
import { useDispatch, useSelector } from 'react-redux';

import toast, { toastConfig, createToast } from 'react-simple-toasts';
import { clearMessage } from './actions/message';
import { getSubStatus } from './actions/users';
import { MessageType } from './constants/message';
import { useTranslation } from 'react-i18next';
import Conditions from './pages/Conditions/conditions';
import AdminLogin from './pages/AdminLogin/login';
import Checkout from './pages/Checkout/checkout';
import FriendAndYour from './pages/FriendAndYour/friendandyour';
import ViewStore from './pages/ViewStore';
import CancelSubscription from './pages/CancelSubscription';
import Notifications from './pages/Notifications';
toastConfig({ theme: 'success', position: 'top-right', className: 'toast-message' }); // configure global toast settings, like theme

function App() {
    const { t, i18n } = useTranslation();
    const { isLoggedIn, user } = useSelector(state => state.auth);
    const substatus = useSelector(state => state.substatus);
    const { message, type } = useSelector(state => state.message);
    const dispatch = useDispatch();

    useEffect(() => {
        if (user)
            dispatch(getSubStatus(user.user._id))
    }, [])

    useEffect(() => {
        if (message) {
            switch (message) {
                case MessageType.SUCCESS_VALIDATE:
                    toast(t('successValidated'), { theme: type })
                    break;
                case MessageType.WARNING_VALIDATE:
                    toast(t('errorSelectCountry'), { theme: type })
                    break;
                case MessageType.WARNING_BLOCKED_ACCOUNT:
                    toast(t('errorBlockAccount'), { theme: type })
                    break;
                case MessageType.WARNING_EXIST_ACCOUNT:
                    toast(t('errorAlreadyExist'), { theme: type })
                    break;
                case MessageType.WARNING_NOT_EXIST_ACCOUNT:
                    toast(t('errorNotFound'), { theme: type })
                    break;
                case MessageType.WARNING_WRONG_PASSWORD:
                    toast(t('errorInvalid'), { theme: type })
                    break;
                case MessageType.SUCCESS_LOGIN:
                    toast(t('successLogin'), { theme: type })
                    break;
                case MessageType.SUCCESS_REGISTER:
                    toast(t('successRegister'), { theme: type })
                    break;
                case MessageType.SUCCESS_UPDATE:
                    toast(t('successUpdate'), { theme: type })
                    break;
                case MessageType.SUCCESS_RESET_PASS:
                    toast(t('successResetPass'), { theme: type })
                    break;
                case MessageType.SUCCESS_SEND_MESSAGE:
                    toast(t('successSendMail'), { theme: type })
                    break;
                case MessageType.WARNING_INVALID_MESSAGE:
                    toast(t('errorInvalidSendMessage'), { theme: type })
                    break;
                case MessageType.WARNING_SUBSCRIPTION:
                    toast(t('errorSubscription'), { theme: type })
                    break;
                case MessageType.WARNING_SUB_LIVE:
                    toast(t('errorSubLive'), { theme: type })
                    break;
                case MessageType.UPDATE_USERS_LIST:
                    toast(t('successUpdate'), { theme: type })
                    break;
                default:
                    toast(message, { theme: type })
                    break;
            }
            dispatch(clearMessage())
        }
    }, [message])
    useEffect(() => {
        if (window.location.pathname === '/') {
            window.location.replace('/register'); // Redirects to /about
        }
    }, []);
    return (

        <Routes>
            <Route path="/login" element={<Login />} />
            <Route path="/admin-login" element={<AdminLogin />} />
            <Route path="/register" element={<Register />} />
            <Route path="/conditions" element={<Conditions />} />
            {isLoggedIn && user?.user?.role == '1' &&
                <>
                    <Route path="/home" element={<Home />} />
                    <Route path="/update-profile" element={<UpdateProfile />} />
                    {/* <Route path="/friendandyour" element={<FriendAndYour />} /> */}
                    <Route path="/checkout" element={<Checkout />} />
                    <Route path="/cancel-subscription" element={<CancelSubscription />} />
                    <Route path="/subscription" element={<Subscription />} />
                    <Route path="/new-store" element={<NewStore />} />
                    <Route path="/view-store/:userId" element={<ViewStore />} />
                    <Route path="/notifications" element={<Notifications />} />
                </>
            }
            {isLoggedIn && user?.user?.role == '0' &&
                <Route path="/dashboard" element={<Dashboard />} />
            }
        </Routes>
    )
}

export default App