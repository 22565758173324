export const MessageType = {
    WARNING_MAIL_VALIDATE: 1,
    WARNING_NOT_EXIST_ACCOUNT: 2,
    WARNING_WRONG_PASSWORD: 3,
    WARNING_BLOCKED_ACCOUNT: 4,
    WARNING_EXIST_ACCOUNT: 5,
    WARNING_NET_DISCONNECTION: 6,
    SUCCESS_VALIDATE: 7,
    WARNING_VALIDATE: 8,
    SUCCESS_REGISTER: 9,
    SUCCESS_LOGIN: 10,
    SUCCESS_UPDATE: 11,
    SUCCESS_RESET_PASS: 12,
    SUCCESS_SEND_MESSAGE: 13,
    WARNING_INVALID_MESSAGE: 14,
    WARNING_SUBSCRIPTION: 15,
    WARNING_SUB_LIVE: 16,
    UPDATE_USERS_LIST: 17,
}