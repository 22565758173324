import axios from "axios";
import authHeader from "./auth-header";
const API_URL = "https://oavlite.com/api1/api/auth/";
// const API_URL = "	http://localhost:8080/api/notification/";

const DeleteNotification = (data) => {
  console.log(data)
  return axios.post(API_URL + "delete", data ,{ headers: authHeader() })
    .then(async (response) => {
      const { status } = response.data;
      return status;
    })
}


const GetNotifications = (data) => {
  return axios.post(API_URL + "get", data,{ headers: authHeader() })
    .then(async (response) => {
      const { data } = response.data;
      console.log(response)
      return response;
    })
}


const UpdateState = (data) => {
  return axios.post(API_URL + "update", data, { headers: authHeader() })
    .then(async (response) => {
      const { data } = response.data;
      return response;
    })
}


const getNotificationState = (data) => {
  return axios.post(API_URL + "get_state", data, { headers: authHeader() })
    .then(async (response) => {
      const { data } = response.data;
      return data;
    })
}

export default {
  DeleteNotification,
  GetNotifications,
  UpdateState,
  getNotificationState
};
