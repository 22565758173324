import { combineReducers } from "redux";
import auth from "./auth";
import message from "./message";
import users from "./users";
import customers from "./customers";
import mail from "./mail";
import substatus from "./substatus";

export default combineReducers({
  auth,
  message,
  users,
  customers,
  mail,
  substatus
});
