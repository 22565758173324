import React, { useEffect, useRef, useState } from 'react';
import Logo from '../../assets/svgs/Logo.png';
import png_checked from '../../assets/pngs/icons8-checked.png';
import png_unchecked from '../../assets/pngs/icons8-unchecked.png';
import { useNavigate } from 'react-router-dom';
import './styles.css';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { GiveTheme } from '../../constants/en';
import { logout } from '../../actions/auth';
import { DeleteUser, getSubStatus, getUserByCountry, sendCustomerEmail, updateFriend } from '../../actions/users';
import { createSubscription } from '../../actions/users';
import { setMessage } from '../../actions/message';
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Modal from 'react-modal';
import { MessageType } from '../../constants/message';
import GivePark from '../../component/GivePark';
import Example from '../../component/Example';
import SLogo from '../../assets/svgs/SLogo.svg'
import { Tooltip } from 'react-tooltip';
import CheckBox from '../../component/CheckBox';
import { SET_MESSAGE } from '../../actions/types';
import storeService from '../../services/store.service';
const customStyles = {
    content: {
        top: '50%',
        left: '50%',
        right: 'auto',
        bottom: 'auto',
        marginRight: '-50%',
        transform: 'translate(-50%, -50%)',
        borderRadius: '20px'
    },
};
function Home() {
    const [mobile, setMobile] = useState(window.innerWidth <= 950);
    const [customerEmail, setCustomerEmail] = useState("");
    const [giveMessage, setGiveMessage] = useState("");
    const [modalIsOpen, setIsOpen] = React.useState(false);
    const { message, type } = useSelector(state => state.message);
    const { hasSubscription } = useSelector(state => state.substatus);
    const [isUpdate, setIsUpdate] = useState(false);
    const [isFriendChecked, setIsFriendChecked] = useState(false);
    const [isStoreExist, setStoreExist] = useState(false);
    const { isLoggedIn, user } = useSelector(state => state.auth);
    const navigate = useNavigate()
    function afterOpenModal() {
        // references are now sync'd and can be accessed.
    }

    function openModal() {
        if (!isStoreExist) {
            dispatch({
                type: SET_MESSAGE,
                payload: { message: "Store configuration required before sending your researches", type: 'warning' },
            });
            return
        }
        if (giveMessage.length == 0) {
            dispatch(setMessage({ message: 'Please write your message', type: 'warning' }));
            return;
        }
        setIsOpen(true);
    }

    function closeModal() {
        setIsOpen(false);
    }

    const handleWindowSizeChange = () => {
        setMobile(window.innerWidth <= 950);
    }

    useEffect(() => {
        storeService.GetAdvices({ id: user.user._id }).then(
            (res) => {
                if (res.data.data.advices?.length !== 0)
                    setStoreExist(true)
                return Promise.resolve
            }
        )
        window.addEventListener('resize', handleWindowSizeChange);
        return () => {
            window.removeEventListener('resize', handleWindowSizeChange);
        }
    }, []);

    useEffect(() => {
        if (message == MessageType.UPDATE_USERS_LIST || message == MessageType.SUCCESS_SEND_MESSAGE) {
            setIsUpdate(true);
        }
    }, [message])

    const { t, i18n } = useTranslation();
    const { users } = useSelector(state => state.users);
    const [selectedTheme, setSelectedTheme] = useState(0);
    const regions = GiveTheme;

    const dispatch = useDispatch();

    const onClickTheme = (value) => {
        setSelectedTheme(value)
    }
    useEffect(() => {
        if (user) {
            let data = {
                id: user?.user?._id
            }
            dispatch(getSubStatus(data));
        }
        let data = {
            is_mine: isFriendChecked,
            email: user?.user?.email
        }
        dispatch(getUserByCountry(data));
        setIsUpdate(false);
    }, [])
    useEffect(() => {
        if (isUpdate) {
            let data = {
                is_mine: isFriendChecked,
                email: user?.user?.email
            }
            dispatch(getUserByCountry(data));
            setIsUpdate(false);
        }
    }, [isUpdate, isFriendChecked])
    const onClickedSendMessage = (email) => {
        if (isLoggedIn && user?.user.role == '1') {
            dispatch(sendCustomerEmail({
                id: user.user._id,
                email: user.user.email,
                message: giveMessage,
                theme: selectedTheme
            }));
        } else {
            dispatch(setMessage({ message: t('Home.AccessiblePage'), type: 'warning' }));
        }
        closeModal();
    }
    const onClickUpdateFriend = (email, id, status) => {
        if (status == 'delete') {
            dispatch(updateFriend({
                status: 'delete',
                from_email: user.user.email,
                to_email: email,
                to_user: id,
                from_user: user.user._id
            }));
        } else {
            dispatch(updateFriend({
                status: 'new',
                from_email: user.user.email,
                to_email: email,
                to_user: id,
                from_user: user.user._id
            }));
        }
    }

    const navigateSubscription = () => {
        console.log(hasSubscription)
        if (hasSubscription)
            navigate('/new-store')
        else
            navigate('/subscription')
        // navigate('/subscription')
    }

    const Logout = () => {
        dispatch(logout());
    }

    const handleNavigateStore = (id) => {
        console.log(id)
        navigate(`/view-store/${id}`)
    }

    const handleChange = (e) => {
        setGiveMessage(e.target.value);
    }
    console.log(document.documentElement.clientHeight)
    const style = isLoggedIn ? { justifyContent: 'center' } : { justifyContent: 'center', alignItems: 'space-between', minHeight: `${document.documentElement.clientHeight - 286}px` }
    return (
        <>
            <div className="home-container">
                {/* <div className="row">
                    <div className="col-md-3">
                        {isLoggedIn && user?.user.role == '1' && <button style={{ width: "100px" }} onClick={Logout} className='btn-green'>{t('Home.Exit')}</button>}
                    </div>
                    <div className="col-md-6">
                        <div className='row main-logo'>
                            <img  src={Logo} alt="" />
                            {isLoggedIn ?
                                <p className='title' style={{ textAlign: 'center', fontSize: '26px', fontWeight: 'bold', color: 'black' }}>{t('Home.GiveToWorld')}</p>
                                :
                                <p className='title' style={{ textAlign: 'center', fontSize: '26px', fontWeight: 'bold', color: 'black' }}>{t('Home.Title')}</p>
                            }
                        </div>
                    </div>

                    <div className="col-md-3" style={{paddingTop:'20px'}}>
                        {isLoggedIn && user?.user.role == '1'
                            ?
                            <>
                                <div className='row' style={{ justifyContent: 'center' }}>
                                    <button onClick={() => { window.location.href = "/update-profile" }} className='btn-green' style={{ width: '70%' }}>{t('Home.Account')}</button>
                                </div>
                            </>
                            :
                            <>
                                <div className='row' style={{ justifyContent: 'center' }}>
                                    <button className='btn-green' style={{width: "60%"}} onClick={() => { window.location.href = "/register" }} >{t('Home.REGISTRATION')}</button>
                                </div>
                                <div className='row' style={{ marginTop: '10px', justifyContent: 'center' }}>
                                    <button className='btn-green' style={{width: "60%"}} onClick={() => { window.location.href = "/login" }}>{t('Home.LOGIN')}</button>
                                </div>
                            </>
                        }
                    </div>
                </div> */}
                {isLoggedIn && <div className='row' style={{ justifyContent: 'center', flexDirection: 'column', alignItems: 'center', marginTop: '50px' }}>
                    <GivePark></GivePark>
                    <span style={{ width: '60px', height: '5px', backgroundColor: 'black', margin: '10px 0px' }}></span>
                    <span style={{ color: 'black', fontWeight: 'bolder', fontSize: '20px', width: 'fit-content', textAlign: 'center' }}>EACH ONLINE RESEARCH IS VISIBLE TO ALL USERS DURING 24 HOURS PREMIUM ACCOUNTS CAN SEND RESEARCHES AND SHOW THEIR STORE</span>
                    <Example></Example>
                </div>}
                <div className='row cards' style={style}>
                    {isLoggedIn && users?.length > 0 && users?.map((item, index) =>
                        <div key={index}>
                            <div className='row' style={{ fontWeight: 'bold' }}>
                                <div className='col-md-12' style={{ display: 'flex' }}>
                                    <div className='col-md-3'>
                                        <div style={{ borderStyle: 'solid', borderLeftWidth: '10px', borderRightWidth: '10px', borderTopWidth: '6px', borderBottomWidth: '6px', borderRadius: 20, textAlign: 'center', padding: 3, margin: 5, marginLeft: 50 }}>
                                            <label>{regions[item.theme].name}</label>
                                        </div>
                                    </div>
                                    <div className='col-md-2'>
                                        <div onClick={() => handleNavigateStore(item.user._id)} data-tooltip-id={`tooltip-${index}`} style={{ borderStyle: 'solid', borderRadius: 20, backgroundColor: 'black', textAlign: 'center', padding: 5, margin: 5 }}>
                                            <label style={{ color: 'white' }}>{'VIEW STORE'}</label>
                                        </div>
                                        <Tooltip
                                            id={`tooltip-${index}`}
                                            className="custom-tooltip"
                                            arrowClassName="custom-tooltip-arrow"
                                            noArrow
                                            opacity={1}
                                            style={{
                                                backgroundColor: 'black',
                                                width: `${document.documentElement.clientWidth * 0.30 + 10}px`,
                                                height: `${document.documentElement.clientHeight * 0.30 + 10}px`,
                                                borderRadius: '30px'
                                            }}
                                            place='bottom-end'
                                        >
                                            <div style={{ transform: 'scale(0.3)', position: 'absolute', left: `-${document.documentElement.clientWidth * 0.35 - 5}px`, top: `-${document.documentElement.clientHeight * 0.35 - 3}px`, borderRadius: '900px', }}>
                                                <iframe width={document.documentElement.clientWidth} height={document.documentElement.clientHeight} src="/view-store/669d526d2fd35be9bec9ff47" style={{ borderRadius: '90px' }} />
                                            </div>
                                        </Tooltip>
                                    </div>
                                    <div className='col-md-4'>
                                        <div style={{ textAlign: 'center', padding: 3, margin: 5 }}>
                                        </div>
                                    </div>
                                    <div className='col-md-3'>
                                        <div style={{ borderStyle: 'solid', borderRadius: 20, backgroundColor: 'black', textAlign: 'center', padding: 5, margin: 5, display: 'flex' }}>
                                            <label style={{ color: 'white', fontSize: 12, marginRight: 5, width: 200, padding: '3px 0px' }}>NUMBER OF FRIENDS</label>
                                            <label style={{ backgroundColor: 'white', color: 'black', borderRadius: 20, fontSize: 16, width: '-webkit-fill-available' }}>{item.friend_count}</label>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className='row mb-2'>
                                <div className='col-md-12' style={{ borderStyle: 'solid', borderRadius: 50, backgroundColor: 'black', display: 'grid', alignItems: 'center', paddingBottom: '10px' }}>
                                    <div className='row'>
                                        <div className='col-md-2' style={{ textAlign: 'center' }}>
                                            <label style={{ color: 'white', fontWeight: 'bold', textAlign: 'center', fontSize: 12 }}>{item.user.username}</label>
                                        </div>
                                        <div className='col-md-7'>
                                            <label style={{ color: 'white', fontSize: 8 }}>IF THIS RESEARCH IS RELEVANT AND FUNNY ADD AS A FRIEND TO VIEW ALL OF THESE RESEARCH AND INCREASE YOUR CUSTOMERS AND SALES DAILY FOR FREE</label>
                                        </div>
                                        <div className='col-md-1' style={{ textAlign: 'end', marginBottom: 0 }}>
                                            {item?.user?.subscription_id.includes("sub_") && <label style={{ color: 'white', fontSize: 10 }}>PREMIUM ACCOUNT</label>}
                                        </div>
                                        <div className='col-md-2' style={{ display: 'flex', alignItems: 'end', justifyContent: 'end' }}>
                                            {/* {item?.is_friend && <CheckBox email = {item.user.email} state = {item.user.is_blocked} refresh = {setIsUpdate}></CheckBox>} */}
                                        </div>
                                    </div>
                                    <div className='row mb-1' >
                                        <div className='col-md-2'>
                                            <p style={{ color: 'white', fontWeight: 'bold', textAlign: 'center', marginBottom: 0, fontSize: 20 }}>RESEARCH</p>
                                        </div>
                                        <div className='col-md-8' style={{ borderStyle: 'solid', borderRadius: 50, backgroundColor: 'white', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                                            <label>{item?.message}</label>
                                        </div>
                                        {(user.user?.subscription_id !== null || hasSubscription == true) ? <div className='col-md-2' style={{ textAlign: 'center', display: 'flex', justifyContent: 'end' }}>
                                            {user?.user?.email != item.email && item.is_friend &&
                                                <button onClick={() => onClickUpdateFriend(item.user.email, item.user._id, 'delete')} style={{ backgroundColor: 'white', color: 'black', width: '60%', fontWeight: 'bold', marginRight: '35px' }}>DELETE FRIEND</button>
                                            }
                                            {user?.user?.email != item.email && !item.is_friend &&
                                                <button onClick={() => onClickUpdateFriend(item.user.email, item.user._id,)} style={{ backgroundColor: 'white', color: 'black', width: '60%', fontWeight: 'bold', marginRight: '35px' }}>ADD FRIEND</button>
                                            }
                                        </div>
                                            :
                                            <div className='col-md-2'></div>}
                                    </div>
                                </div>
                            </div>
                        </div>)
                    }
                    {isLoggedIn && <div style={{ height: '100px' }}></div>}
                    {isLoggedIn && user?.user.role == '1' && users?.length == 0 &&
                        <div style={{ height: '500px', alignItems: 'center', justifyContent: 'center', display: 'flex' }}>
                            <p className='description'>{t('Home.NoProfile')}</p>
                        </div>
                    }
                    {(!isLoggedIn || user?.user.role != '1') &&
                        <div className='offering-container' style={{ alignItems: 'center', justifyContent: 'center', display: 'flex', marginTop: '50px' }}>
                            <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-between', height: '100%' }}>
                                <p style={{ marginBottom: "5px", textAlign: 'center' }}>A STABLE AND RELIABLE SOLUTION TO SIMPLY DEVELOP YOUR DAILY KNOWLEDGE AND YOUR INCOME ALL YEAR AROUND</p>
                                <p style={{ marginBottom: "10px", textAlign: 'center' }}>CREATE YOUR UNIQUE DANCE CHARACTER AND GET STARTED NOW</p>
                                <p className='sub-description'>SELL DANCE ADVICES FOR WOMEN AND CHILDREN</p>
                                <p style={{ marginBottom: "10px", textAlign: 'center' }}>BUY AND EASILY MAKE BEAUTIFUL FAMILY MEMORIES BY PURCHASING OUR REPRODUCEABLE DANCES TO SEE AND SHOW YOUR CHILDREN</p>
                                <p className='description'>"{t('Home.OurAccessible')}"</p>
                                <p style={{ textAlign: 'center' }}>LAUGH JOY FRIENDSHIP MONEY</p>
                                <p style={{ textAlign: 'center' }}>EARN NEW CUSTOMERS DAILY & INCREASE YOUR POSITIVE BUSINESS</p>

                                <p className='sub-description' style={{ margin: '60px 0px' }}>SELL DANCE ADVICES FOR WOMEN AND CHILDREN</p>

                                <p style={{ textAlign: 'center', marginTop: '60px' }}>Subscribe to the subscription and follow the instructions</p>
                                <p style={{ textAlign: 'center' }}>The site is the new contribution of a client can take time to be patient to generate your first euros and always more and more</p>
                            </div>
                        </div>
                    }

                </div>
            </div >
            {isLoggedIn &&
                <div className='content-send'>

                    <div className='row' style={{ marginTop: 10 }}>
                        <div className='col-md-2'>
                        </div>
                        <div className='col-md-1' style={{ textAlign: 'center' }}>
                            <label style={{ color: 'white', fontWeight: 'bold', textAlign: 'center', fontSize: 10, marginTop: 20 }}>720/{giveMessage.length}</label>
                        </div>
                        <div className='col-md-2'>
                            <button style={{ marginTop: '5px', backgroundColor: 'white', color: 'black', fontWeight: 'bold', paddingTop: 4, paddingBottom: 4, width: '-webkit-fill-available', fontSize: 12 }} onClick={() => setIsUpdate(true)}>RELOAD PAGE FOR DISPLAY NEW POSTED</button>
                        </div>
                        <div className='col-md-3'>
                            <div style={{ marginTop: '5px', backgroundColor: 'white', height: '30px', color: 'black', borderRadius: '30px', fontWeight: 'bold', marginLeft: '30px', marginRight: '30px', paddingTop: 6, paddingBottom: 4, width: '-webkit-fill-available', fontSize: 12, cursor: 'pointer' }} onClick={() => navigateSubscription()}>
                                PREMIUM ACCOUNT
                            </div>
                        </div>
                        <div className='col-md-2' style={{ textAlign: 'end', display: "flex" }}>
                            <label style={{ color: 'white', paddingRight: 5, fontSize: '26px', fontWeight: 'bold' }}>THEME</label>
                            <div style={{ backgroundColor: 'white', display: 'flex', borderRadius: 50, margin: '5px 0px' }}>
                                <select value={selectedTheme} onChange={(e) => onClickTheme(e.target.value)} className='select-region'>
                                    {regions && regions.map((item, index) => <option value={item.id} key={item + index}>{item.name}</option>)}
                                </select>
                            </div>
                        </div>
                        <div className='col-md-2' style={{ textAlign: 'center' }}>
                            {/* <button onClick={() => { window.location.href = "/subscription" }} style={{ backgroundColor: 'white', color: 'black', width: '70%', fontWeight: 'bold', paddingTop: 4, paddingBottom: 4 }}>NUMBER OF FRIEND</button> */}
                        </div>
                    </div>
                    <div className='row'>
                        <div className='col-md-2'>
                            <p style={{ color: 'white', fontWeight: 'bold', textAlign: 'center', marginBottom: 0, fontSize: 11 }}>SEND 24 HOUR EARN NOTORIETY</p>
                            <p style={{ color: 'white', fontWeight: 'bold', textAlign: 'center', marginBottom: 0, fontSize: 16, lineHeight: '0.9' }}>INDICATE A RESEARCH?</p>
                        </div>
                        <div className='col-md-8' style={{ borderStyle: 'solid', borderRadius: 50, backgroundColor: 'white' }}>
                            <input onChange={(e) => handleChange(e)} maxLength={720} className='input-message'></input>
                        </div>
                        <div className='col-md-2' style={{ textAlign: 'center' }}>
                            {hasSubscription && <button onClick={() => openModal()} style={{ backgroundColor: 'white', color: 'black', width: '70%', fontWeight: 'bold', paddingTop: 4 }}>SEND</button>}
                        </div>
                    </div>
                </div>
            }


            <Modal
                isOpen={modalIsOpen}
                onAfterOpen={afterOpenModal}
                onRequestClose={closeModal}
                style={customStyles}
                contentLabel="Example Modal"
            >
                <p className='modal-content'>{t('Home.SendModalTitle')}</p>
                <div style={{ display: 'flex', justifyContent: 'end' }}>
                    <button className='modal-btn-send btn-send' onClick={() => onClickedSendMessage(customerEmail)}>{t('Home.SendModalSend')}</button>
                    <button className='modal-btn-send btn-send' onClick={closeModal}>{t('Home.SendModalNo')}</button>
                </div>
            </Modal>
        </>
    )
}

export default Home;
