import axios from 'axios'
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import notificationService from '../../services/notification.service';
import './index.css'
import NotificationItem from './NotificationItem';
import { updateNotificationState } from '../../actions/auth';

const API_URL = "localhost:8000/api/store/"

const Notifications = () => {
    const dispatch = useDispatch()
    const { isLoggedIn, user } = useSelector(state => state.auth);
    const {substatus} = useSelector(state=> state.substatus)
    const [notifications, setNotifications] = useState(null)


    const getData = () => {
        console.log('refresh')
        notificationService.GetNotifications({ user: user.user._id }).then(
            (res) => {

                setNotifications([...res.data.data])
                return Promise.resolve
            }
        )
    }

    useEffect(() => {
        console.log("substatus",substatus)
        dispatch(updateNotificationState({ user: user.user._id, isUpdate: true }))
        getData()
    }, [])
    return (
        <div className='content' style={{ minHeight: '54%' }}>
            <div>
                <p className='title' style={{ textAlign: 'center', fontSize: '22px', fontWeight: 'bold', color: 'black' }}>ALL NOTIFICATIONS</p>
            </div>
            <div style={{display:'flex', flexDirection:'column', alignItems:'center'}}>
                {notifications?.map((item, index) => {
                    return (
                        <NotificationItem data={item} key = {`notification-${index}`} getData ={getData}/>
                    )
                })}
            </div>
        </div>
    )
}

export default Notifications;