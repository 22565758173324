import { SET_SUBSTATUS } from "../actions/types";

const initialState = { hasSubscription: false };

export default function (state = initialState, action) {
  const { type, payload } = action;
  console.log(type)
  switch (type) {
    case SET_SUBSTATUS:
      return {...payload};
    default:
      return state;
  }
}
