import { GET_CUSTOMERS_SUCCESS, GET_CUSTOMERS_FAIL, UPDATE_CUSTOMER_SUCCESS } from "../actions/types";

const initialState = { customers: {} };

export default function (state = initialState, action) {
  const { type, payload } = action;

  switch (type) {
    case GET_CUSTOMERS_SUCCESS:
      return { customers: payload };

    case UPDATE_CUSTOMER_SUCCESS:
      let customers = state.customers.allUsers;
      let item = payload;
      var foundIndex = customers.findIndex(x => x._id == item._id);
      customers[foundIndex].active = item.active;

      let sessions = { ...state };
      sessions.allUsers = customers;

      return { customers: sessions }

    case GET_CUSTOMERS_FAIL:
      return state;

    default:
      return state;
  }
}
