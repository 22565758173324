
import { useNavigate, useParams } from 'react-router-dom';
import Logo from '../../assets/svgs/Logo.png';
import './styles.css';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { createSubscription, updateExpiredAt, updateProfile } from '../../actions/users';
import { setMessage } from '../../actions/message';

function Checkout() {
    const navigate = useNavigate();
    const { isLoggedIn, user } = useSelector(state => state.auth);
    const { t, i18n } = useTranslation();
    const dispatch = useDispatch();
    useEffect(() => {
        let data = {
            sessionId: user.session_id,
            id: user.user._id
        }
        dispatch(updateExpiredAt(data))
    }, [])

    return (
        <>
            <div className="content"  style={{ display:'block',minHeight:'calc(100vh - 285px)' }}>
                <div className='row subscription' style={{ justifyContent: "center" }}>
                    <div style={{ justifyContent: "center", alignItems: "center", display: "contents" }}>
                        <label style={{ marginTop: "220px", marginBottom: "20px", fontSize:'22px' }}>{t('Subscription.SubTitle')}</label>
                    </div>
                </div>
            </div>
        </>
    )
}

export default Checkout;
