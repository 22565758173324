import React from 'react'
import './index.css'
const EclipseForm = ({title, value}) => {
    return (<div className='eclipse-container'>
        <div className='black-title'>
            {title}
        </div>
        <div className='white-value'>
            {value}
        </div>
    </div>)
}

export default EclipseForm;