export const GiveTheme = [
    { id: 1, name: 'Sports' },
    { id: 2, name: 'Food' },
    { id: 3, name: 'Music' },
    { id: 4, name: 'Game' },
    { id: 5, name: 'Funny' },
    { id: 6, name: 'Movie' },
    { id: 7, name: 'Fashion' },
    { id: 8, name: 'Commercial' },
    { id: 9, name: 'Love & Dating' },
    { id: 10, name: 'News & People' },
    { id: 11, name: 'Art & drawing' },
    { id: 12, name: 'Request & Help' },
    { id: 13, name: 'Science & Discovery' },
    { id: 14, name: 'Philosophy & Medicine' },
    { id: 15, name: 'Entrepreneurship & Business' },
    { id: 16, name: 'Not classified' },
]

export const EnFranceRegion = [
    { id: 0, name: 'Alsace' },
    { id: 1, name: 'Aquitaine' },
    { id: 2, name: 'Auvergne' },
    { id: 3, name: 'Auvergne-Rhône-Alpes' },
    { id: 4, name: 'Lower Normandy' },
    { id: 5, name: 'Burgundy' },
    { id: 6, name: 'Burgundy-Franche-Comté' },
    { id: 7, name: 'Brittany' },
    { id: 8, name: 'Center' },
    { id: 9, name: 'Champagne-Ardenne' },
    { id: 10, name: 'Corsica' },
    { id: 11, name: 'Franche-Comté' },
    { id: 12, name: 'Grand Est' },
    { id: 13, name: 'Guadeloupe' },
    { id: 14, name: 'Guyana' },
    { id: 15, name: 'Upper Normandy' },
    { id: 16, name: 'Hauts -de-France' },
    { id: 17, name: 'Île-de-France' },
    { id: 18, name: 'Réunion' },
    { id: 19, name: 'Languedoc-Roussillon' },
    { id: 20, name: 'Languedoc-Roussillon-Midi-Pyrénées' },
    { id: 21, name: 'Limousin' },
    { id: 22, name: 'Lorraine' },
    { id: 23, name: 'Martinique' },
    { id: 24, name: 'Mayotte' },
    { id: 25, name: 'Midi-Pyrénées' },
    { id: 26, name: 'Nord-Pas-de-Calais' },
    { id: 27, name: 'Normandy' },
    { id: 28, name: 'Nouvelle- Aquitaine' },
    { id: 29, name: 'Pays de la Loire' },
    { id: 30, name: 'Picardy' },
    { id: 31, name: 'Poitou-Charentes' },
    { id: 32, name: 'Provence-Alpes-Côte d’Azur' },
    { id: 33, name: 'Rhône-Alpes' },
]

export const EnCanadaRegion = [
    { id: 0, name: 'Ontario' },
    { id: 1, name: 'Quebec' },
    { id: 2, name: 'Nova Scotia' },
    { id: 3, name: 'New Brunswick' },
    { id: 4, name: 'Manitoba' },
    { id: 5, name: 'British Columbia' },
    { id: 6, name: 'Prince Edward Island' },
    { id: 7, name: 'Saskatchewan' },
    { id: 8, name: 'Alberta' },
    { id: 9, name: 'Newfoundland and Labrador' },
    { id: 10, name: 'Yukon' },
    { id: 11, name: 'Northwest Territories' },
    { id: 12, name: 'Nunavut' },
]

export const EnGermanyRegion = [
    { id: 0, name: 'Baden-Württemberg' },
    { id: 1, name: 'Lower Saxony' },
    { id: 2, name: 'Bavaria' },
    { id: 3, name: 'Berlin' },
    { id: 4, name: 'Brandenburg' },
    { id: 5, name: 'Bremen' },
    { id: 6, name: 'Hamburg' },
    { id: 7, name: 'Hesse' },
    { id: 8, name: 'Mecklenburg-Western Pomerania' },
    { id: 9, name: 'North Rhine-Westphalia' },
    { id: 10, name: 'Rhineland-Palatinate' },
    { id: 11, name: 'Saarland' },
    { id: 12, name: 'Saxony' },
    { id: 13, name: 'Saxony-Anhalt' },
    { id: 14, name: 'Schleswig-Holstein and Thuringia' },
]

export const EnNetherlandsRegion = [
    { id: 0, name: 'North Brabant' },
    { id: 1, name: 'Drenthe' },
    { id: 2, name: 'Flevoland' },
    { id: 3, name: 'Friesland' },
    { id: 4, name: 'Groningen' },
    { id: 5, name: 'Gelderland' },
    { id: 6, name: 'South Holland' },
    { id: 7, name: 'North Holland' },
    { id: 8, name: 'Limburg' },
    { id: 9, name: 'Overijssel' },
    { id: 10, name: 'Utrecht' },
    { id: 11, name: 'Zeeland' },
]

export const EnLuxembourgRegion = [
    { id: 0, name: 'Arlon/Attert/Habay/Martelange' },
    { id: 1, name: 'Centre-Ardenne' },
    { id: 2, name: 'Gaume' },
    { id: 3, name: 'Famenne-Ardenne' },
    { id: 4, name: 'Semois-et-Lesse and South-Luxembourg' },
]

export const EnBelgiumRegion = [
    { id: 0, name: 'Brussels' },
    { id: 1, name: 'Walloon' },
    { id: 2, name: 'Flemish' },
]

export const EnPortugalRegion = [
    { id: 0, name: 'North' },
    { id: 1, name: 'Center' },
    { id: 2, name: 'Lisbon' },
    { id: 3, name: 'Alentejo' },
    { id: 4, name: 'Algarve' },
    { id: 5, name: 'Azores' },
    { id: 6, name: 'Madeira' },
]

export const EnItalyRegion = [
    { id: 0, name: 'Abruzzo' },
    { id: 1, name: 'Basilicata' },
    { id: 2, name: 'Calabria' },
    { id: 3, name: 'Campania' },
    { id: 4, name: 'Emilia-Romagna' },
    { id: 5, name: 'Friuli' },
    { id: 6, name: 'Veneto' },
    { id: 7, name: 'Giulia' },
    { id: 8, name: 'Lazio' },
    { id: 9, name: 'Liguria' },
    { id: 10, name: 'Lombardy' },
    { id: 11, name: 'Marche' },
    { id: 12, name: 'Molise' },
    { id: 13, name: 'Umbria' },
    { id: 14, name: 'Piedmont' },
    { id: 15, name: 'Puglia' },
    { id: 16, name: 'Sardinia' },
    { id: 17, name: 'Sicily' },
    { id: 18, name: 'Tuscany' },
    { id: 19, name: 'Trentino-Alto Adige' },
    { id: 20, name: 'Aosta Valley ' },
    { id: 21, name: 'Veneto' }
]

export const EnSpainRegion = [
    { id: 0, name: 'Andalusia' },
    { id: 1, name: 'Aragon' },
    { id: 2, name: 'Asturias' },
    { id: 3, name: 'Balearic Islands' },
    { id: 4, name: 'Basque Country' },
    { id: 5, name: 'Canary Islands' },
    { id: 6, name: 'Cantabria' },
    { id: 7, name: 'Castile La Mancha' },
    { id: 8, name: 'Castile and Leon' },
    { id: 9, name: 'Catalonia' },
    { id: 10, name: 'Extremadura' },
    { id: 11, name: 'Galicia' },
    { id: 12, name: 'Madrid' },
    { id: 13, name: 'Murcia' },
    { id: 14, name: 'Navarre' },
    { id: 15, name: 'La Rioja' },
    { id: 16, name: 'Valencia' },
    { id: 17, name: 'Ceuta' },
    { id: 18, name: 'Melilla' }
]

export const EnCountries = [
    { id: 0, name: 'France' },
    { id: 1, name: 'Canada' },
    { id: 2, name: 'Germany' },
    { id: 3, name: 'Spain' },
    { id: 4, name: 'Portugal' },
    { id: 5, name: 'Italy' }
]

