import axios from "axios";
import authHeader from "./auth-header";

const API_URL = "https://oavlite.com/api1/api/user/";
// const API_URL = "	http://localhost:8080/api/user/";
const user = JSON.parse(localStorage.getItem("user"));

const updateProfile = (formData) => {
  return axios.post(API_URL + "update", formData, { headers: authHeader() })
    .then((response) => {
      const { data } = response.data;
      if (data.access_token) {
        localStorage.setItem("user", JSON.stringify(data));
      }
      return response.data;
    });
}

const updateChannel = (data) => {
  return axios.post(API_URL + "update-channel", data, { headers: authHeader() })
    .then((response) => {
      const { data } = response.data;
      if (data.access_token) {
        localStorage.setItem("user", JSON.stringify(data));
      }
      return response.data;
    });
}

const getUserByCountry = (data) => {
  return axios.post(API_URL + "get-user-by-country", data)
    .then((response) => {
      const { data } = response.data;
      return data;
    });
}

const updateExpiredAt = (data) => {
  return axios.post(API_URL + "update-expired-at", data, { headers: authHeader() })
    .then((response) => {
      const { data } = response.data;
      return data;
    });
}

const createSubscription = (data) => {
  return axios.post(API_URL + "checkout-session", data, { headers: authHeader() })
    .then(async (response) => {
      return response.data;
    })
}

const getUserSession = (data) => {
  return axios.post(API_URL + "get-user-session", data, { headers: authHeader() })
    .then(async (response) => {
      const { data } = response.data;
      return data;
    })
}

const sendCustomerEmail = (data) => {
  return axios.post(API_URL + "send-customer-email", data, { headers: authHeader() })
    .then(async (response) => {
      return response.data;
    })
}

const updateFriend = (data) => {
  return axios.post(API_URL + "update-friend", data, { headers: authHeader() })
    .then(async (response) => {
      return response.data;
    })
}

const DeleteUser = () => {
  return axios.get(API_URL + "delete-user", { headers: authHeader() })
    .then(async (response) => {
      const { data } = response.data;
      return data;
    })
}

const getMailInfo = (data) => {
  return axios.post(API_URL + "get-mail-info", data, { headers: authHeader() })
    .then(async (response) => {
      return response.data;
    })
}

const updateActive = (data) => {
  return axios.post(API_URL + "update-active", data, { headers: authHeader() })
    .then(async (response) => {
      return response.data;
    })
}

const getSubStatus = (data) => {
  return axios.post(API_URL + "get-substatus", data, { headers: authHeader() })
    .then(async (response) => {
      return response.data;
    })
}

const blockUser = (data) => {
  return axios.post(API_URL + "block_user", data, { headers: authHeader() })
    .then(async (response) => {
      return response.data;
    })
}

const CancelSubscription = (data) => {
  return axios.post(API_URL + "cancel-subscription", data, { headers: authHeader() })
    .then(async (response) => {
      return response;
    })
}

export default {
  updateProfile,
  updateChannel,
  getUserByCountry,
  updateExpiredAt,
  createSubscription,
  getUserSession,
  sendCustomerEmail,
  DeleteUser,
  getMailInfo,
  updateActive,
  updateFriend,
  blockUser,
  getSubStatus,
  CancelSubscription
};