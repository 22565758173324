import React, { useState } from 'react'
import './index.css'
import FeatherIcon from 'feather-icons-react';
import userService from '../../services/user.service';
const CheckBox = ({ email, state, refresh }) => {
    const [isBlocked, setBlocked] = useState(state)
    const handleClickBlock = async () => {
        console.log(email)
        const res = await userService.blockUser({ email: email, state: !isBlocked })
        console.log(res)
        setBlocked(!isBlocked)
        refresh(true)
    }
    return (
        <div className='block-checkbox'>
            <label className='block-checkbox-title'>BLOCK THIS ACCOUNT RESEARCH</label>
            <div className='block-checkbox-icon' onClick={handleClickBlock}
            >
                {isBlocked && <FeatherIcon size="18" icon="check" />}
            </div>
        </div>
    )
}

export default CheckBox