import React, { useEffect, useState } from 'react'
import './index.css'
import {  useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import storeService from '../../services/store.service'
const Advice = ({title, id, refresh, viewMode}) => {
    const location = useLocation()
    const { user } = useSelector(state => state.auth);
    const onClickDelete = () => {
        storeService.DeleteAdvice({id:id, user: user.user._id}).then(
            (res) => {
                refresh()
            }
        )
    }

    return (
        <div className='advice-container'>
            { !viewMode && <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                <button className='black-btn' style={{width:'100px', padding:'3px 10px', fontSize:'12px', height:'30px'}} onClick={onClickDelete}>DELETE</button>
            </div>}
            <div className='advice-title'>
                <p style={{ fontSize: '24px', margin: '0px', color: 'white' }}>
                    {title? title:''}
                </p>
                {/* <p style={{ fontSize: '12px', color: 'white', margin: '0px' }}>CONTACT FOR THIS OFFER</p> */}
            </div>
        </div>
    )
}

export default Advice;