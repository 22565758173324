import {
    UPDATE_SUCCSS,
    UPDATE_FAIL,
    SET_MESSAGE,
    GET_USERS_SUCCESS,
    GET_CUSTOMERS_SUCCESS,
    SET_MAIL_COUNT,
    UPDATE_CUSTOMER_SUCCESS,
    SET_SUBSTATUS,
} from "./types";

import UserService from "../services/user.service";
import { MessageType } from "../constants/message";
import { useSelector } from "react-redux";
import userService from "../services/user.service";
const user = JSON.parse(localStorage.getItem("user"));

export const updateProfile = (formData) => (dispatch) => {
    return UserService.updateProfile(formData).then(
        (result) => {
            const { data, status, message } = result;
            if (status == 200) {
                dispatch({
                    type: UPDATE_SUCCSS,
                    payload: { user: data },
                });
                dispatch({
                    type: SET_MESSAGE,
                    payload: { message: MessageType.SUCCESS_UPDATE, type: 'success' },
                });
            } else if (status == 400) {
                dispatch({
                    type: SET_MESSAGE,
                    payload: { message: MessageType.WARNING_NOT_EXIST_ACCOUNT, type: 'warning' },
                });
            } else {
                dispatch({
                    type: SET_MESSAGE,
                    payload: { message: message, type: 'warning' },
                });
            }
            return Promise.resolve();
        },
        (error) => {
            const message =
                (error.response &&
                    error.response.data &&
                    error.response.data.message) ||
                error.message ||
                error.toString();

            dispatch({
                type: UPDATE_FAIL,
            });

            dispatch({
                type: SET_MESSAGE,
                payload: { message: message, type: 'warning' },
            });

            return Promise.reject();
        }
    );
};

export const updateChannel = (data) => (dispatch) => {
    return UserService.updateChannel(data).then(
        (result) => {
            const { data, status, message } = result;
            if (status == 200) {
                let userInfo = { access_token: user.access_token, user: data.user };
                dispatch({
                    type: UPDATE_SUCCSS,
                    payload: { user: userInfo },
                });
                dispatch({
                    type: SET_MESSAGE,
                    payload: { message: MessageType.SUCCESS_VALIDATE, type: 'success' },
                });
            } else {
                dispatch({
                    type: SET_MESSAGE,
                    payload: { message: MessageType.WARNING_VALIDATE, type: 'warning' },
                });
            }
            return Promise.resolve();
        },
        (error) => {
            const message =
                (error.response &&
                    error.response.data &&
                    error.response.data.message) ||
                error.message ||
                error.toString();

            dispatch({
                type: SET_MESSAGE,
                payload: { message: message, type: 'warning' },
            });

            return Promise.reject();
        }
    );
};

export const getUserByCountry = (data) => (dispatch) => {
    return UserService.getUserByCountry(data).then(
        (data) => {
            dispatch({
                type: GET_USERS_SUCCESS,
                payload: data,
            });
            return Promise.resolve();
        },
        (error) => {
            const message =
                (error.response &&
                    error.response.data &&
                    error.response.data.message) ||
                error.message ||
                error.toString();

            dispatch({
                type: SET_MESSAGE,
                payload: { message: message, type: 'warning' },
            });

            return Promise.reject();
        }
    );
};

export const updateActive = (data) => (dispatch) => {
    return UserService.updateActive(data).then(
        (result) => {
            const { data, status, message } = result;
            dispatch({
                type: UPDATE_CUSTOMER_SUCCESS,
                payload: data.user,
            });
            return Promise.resolve();
        },
        (error) => {
            const message =
                (error.response &&
                    error.response.data &&
                    error.response.data.message) ||
                error.message ||
                error.toString();

            dispatch({
                type: SET_MESSAGE,
                payload: { message: message, type: 'warning' },
            });

            return Promise.reject();
        }
    );
};


export const updateExpiredAt = (data) => (dispatch) => {
    return UserService.updateExpiredAt(data).then(
        (result) => {
            let data = { access_token: user.access_token, user: result.user };
            dispatch({
                type: UPDATE_SUCCSS,
                payload: { user: data },
            });
            return Promise.resolve();
        },
        (error) => {
            const message =
                (error.response &&
                    error.response.data &&
                    error.response.data.message) ||
                error.message ||
                error.toString();

            dispatch({
                type: SET_MESSAGE,
                payload: { message: message, type: 'warning' },
            });

            return Promise.reject();
        }
    );
};

export const createSubscription = (data) => (dispatch) => {
    return UserService.createSubscription(data).then(
        async (result) => {
            const { data, status, message } = result;
            if (status == 200) {
                let userInfo = { ...user, session_id: data.id };
                await localStorage.setItem("user", JSON.stringify(userInfo));
                window.location = data.url;
            } else if (status == 401) {
                dispatch({
                    type: SET_MESSAGE,
                    payload: { message: MessageType.WARNING_SUBSCRIPTION, type: 'warning' },
                });
            } else if (status == 402) {
                dispatch({
                    type: SET_MESSAGE,
                    payload: { message: MessageType.WARNING_SUB_LIVE, type: 'warning' },
                });
            }
            return Promise.resolve();
        },
        (error) => {
            const message =
                (error.response &&
                    error.response.data &&
                    error.response.data.message) ||
                error.message ||
                error.toString();

            dispatch({
                type: SET_MESSAGE,
                payload: { message: message, type: 'warning' },
            });

            return Promise.reject();
        }
    );
};

export const getUserSession = (data) => (dispatch) => {
    console.log(data)
    return UserService.getUserSession(data).then(
        (data) => {
            dispatch({
                type: GET_CUSTOMERS_SUCCESS,
                payload: data,
            });
            return Promise.resolve();
        },
        (error) => {
            const message =
                (error.response &&
                    error.response.data &&
                    error.response.data.message) ||
                error.message ||
                error.toString();

            dispatch({
                type: SET_MESSAGE,
                payload: { message: message, type: 'warning' },
            });

            return Promise.reject();
        }
    );
};

export const sendCustomerEmail = (data) => (dispatch) => {
    return UserService.sendCustomerEmail(data).then(
        (data) => {
            if (data.status == 200) {
                dispatch({
                    type: SET_MESSAGE,
                    payload: { message: MessageType.SUCCESS_SEND_MESSAGE, type: 'success' },
                });
            } else if (data.status == 400) {
                dispatch({
                    type: SET_MESSAGE,
                    payload: { message: MessageType.WARNING_INVALID_MESSAGE, type: 'warning' },
                });
            }
            return Promise.resolve();
        },
        (error) => {
            const message =
                (error.response &&
                    error.response.data &&
                    error.response.data.message) ||
                error.message ||
                error.toString();

            dispatch({
                type: SET_MESSAGE,
                payload: { message: message, type: 'warning' },
            });

            return Promise.reject();
        }
    );
};

export const updateFriend = (data) => (dispatch) => {
    return UserService.updateFriend(data).then(
        (data) => {
            if (data.status == 200) {
                dispatch({
                    type: SET_MESSAGE,
                    payload: { message: MessageType.UPDATE_USERS_LIST, type: 'success' },
                });
            } else if (data.status == 400) {
                dispatch({
                    type: SET_MESSAGE,
                    payload: { message: MessageType.WARNING_INVALID_MESSAGE, type: 'warning' },
                });
            }
            return Promise.resolve();
        },
        (error) => {
            const message =
                (error.response &&
                    error.response.data &&
                    error.response.data.message) ||
                error.message ||
                error.toString();

            dispatch({
                type: SET_MESSAGE,
                payload: { message: message, type: 'warning' },
            });

            return Promise.reject();
        }
    );
};

export const DeleteUser = () => (dispatch) => {
    return UserService.DeleteUser().then(
        (data) => {
            return Promise.resolve();
        },
        (error) => {
            const message =
                (error.response &&
                    error.response.data &&
                    error.response.data.message) ||
                error.message ||
                error.toString();

            dispatch({
                type: SET_MESSAGE,
                payload: { message: message, type: 'warning' },
            });

            return Promise.reject();
        }
    );
};

export const getMailInfo = (data) => (dispatch) => {
    return UserService.getMailInfo(data).then(
        (data) => {
            console.log(data.data)
            if (data.status == 200) {
                dispatch({
                    type: SET_MAIL_COUNT,
                    payload: { count: data.data.count, customers: data.data.customers },
                });
            } else {
                // dispatch({
                //     type: SET_MESSAGE,
                //     payload: { message: MessageType.WARNING_INVALID_MESSAGE, type: 'warning' },
                // });
            }
            return Promise.resolve();
        },
        (error) => {
            const message =
                (error.response &&
                    error.response.data &&
                    error.response.data.message) ||
                error.message ||
                error.toString();

            dispatch({
                type: SET_MESSAGE,
                payload: { message: message, type: 'warning' },
            });

            return Promise.reject();
        }
    );
};

export const getSubStatus = (data) => (dispatch) => {
    return UserService.getSubStatus(data).then(
        (data) => {
            console.log(data)
            if (data.status == 200) {
                dispatch({
                    type: SET_SUBSTATUS,
                    payload: data.data,
                });
            } else {
                dispatch({
                    type: SET_SUBSTATUS,
                    payload: data.data,
                });
            }
            return Promise.resolve();
        },
        (error) => {
            const message =
                (error.response &&
                    error.response.data &&
                    error.response.data.message) ||
                error.message ||
                error.toString();

            dispatch({
                type: SET_MESSAGE,
                payload: { message: message, type: 'warning' },
            });

            return Promise.reject();
        }
    );
};

export const canCelSubscription = (data) => (dispatch) => {
    return userService.CancelSubscription(data).then(
        (data) => {
            console.log(data)
            if (data?.data.statusCode == 200) {
                dispatch({
                    type: SET_MESSAGE,
                    payload: { message: "Subscription canceled", type: 'success' },
                });
            }
            else
                dispatch({
                    type: SET_MESSAGE,
                    payload: { message: data?.data.raw.message, type: 'warning' },
                });
            return Promise.resolve();
        },
        (error) => {
            const message =
                (error.response &&
                    error.response.data &&
                    error.response.data.message) ||
                error.message ||
                error.toString();

            dispatch({
                type: SET_MESSAGE,
                payload: { message: message, type: 'warning' },
            });

            return Promise.reject();
        }
    )
}