import React, { useEffect, useRef, useState } from 'react';
import Logo from '../../assets/svgs/Logo.png';
import './styles.css';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { GiveTheme } from '../../constants/en';
import { logout } from '../../actions/auth';
import { DeleteUser, getUserByCountry, sendCustomerEmail, updateFriend } from '../../actions/users';
import { createSubscription } from '../../actions/users';
import { setMessage } from '../../actions/message';
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Modal from 'react-modal';
import { MessageType } from '../../constants/message';
import Subscription from '../Subscription/subscription';
const customStyles = {
    content: {
        top: '50%',
        left: '50%',
        right: 'auto',
        bottom: 'auto',
        marginRight: '-50%',
        transform: 'translate(-50%, -50%)',
        borderRadius: '20px'
    },
};
function FriendAndYour() {
    const [mobile, setMobile] = useState(window.innerWidth <= 950);
    const [customerEmail, setCustomerEmail] = useState("");
    const [giveMessage, setGiveMessage] = useState("");
    const [modalIsOpen, setIsOpen] = React.useState(false);
    const [isUpdate, setIsUpdate] = useState(false);
    const { message, type } = useSelector(state => state.message);
    const { hasSubscription } = useSelector(state => state.substatus);

    function afterOpenModal() {
        // references are now sync'd and can be accessed.
    }

    function openModal() {
        if (giveMessage.length == 0) {
            dispatch(setMessage({ message: 'Please write your message', type: 'warning' }));
            return;
        }
        setIsOpen(true);
    }

    function closeModal() {
        setIsOpen(false);
    }

    const handleWindowSizeChange = () => {
        setMobile(window.innerWidth <= 950);
    }

    useEffect(() => {
        window.addEventListener('resize', handleWindowSizeChange);
        return () => {
            window.removeEventListener('resize', handleWindowSizeChange);
        }
    }, []);
    useEffect(() => {
        if (message == MessageType.UPDATE_USERS_LIST || message == MessageType.SUCCESS_SEND_MESSAGE) {
            setIsUpdate(true);
        }
    }, [message])
    const { t, i18n } = useTranslation();
    const { isLoggedIn, user } = useSelector(state => state.auth);
    const { users } = useSelector(state => state.users);
    const [selectedTheme, setSelectedTheme] = useState(0);
    const regions = GiveTheme;

    const dispatch = useDispatch();

    const onClickTheme = (value) => {
        setSelectedTheme(value)
    }
    useEffect(() => {
        if (isUpdate) {
            let data = {
                is_mine: true,
                email: user?.user?.email
            }
            dispatch(getUserByCountry(data));
            setIsUpdate(false);
        }
    }, [isUpdate])
    useEffect(() => {
        let data = {
            is_mine: true,
            email: user?.user?.email
        }
        dispatch(getUserByCountry(data));
    }, [selectedTheme])

    const onClickedSendMessage = (email) => {
        if (isLoggedIn && user?.user.role == '1') {
            dispatch(sendCustomerEmail({
                id: user.user._id,
                email: user.user.email,
                message: giveMessage,
                theme: selectedTheme
            }));
        } else {
            dispatch(setMessage({ message: t('Home.AccessiblePage'), type: 'warning' }));
        }
        closeModal();
    }
    const onClickUpdateFriend = (status) => {
        if (status == 'delete') {
            dispatch(updateFriend({
                status: 'delete',
                from_email: user.user.email,
                to_email: user.user.email,
                to_user: user.user._id,
                from_user: user.user._id
            }));
        } else {
            dispatch(updateFriend({
                status: 'new',
                from_email: user.user.email,
                to_email: user.user.email,
                to_user: user.user._id,
                from_user: user.user._id
            }));
        }
    }
    const Logout = () => {
        dispatch(logout());
    }

    const handleChange = (e) => {
        setGiveMessage(e.target.value);
    }
    return (
        <>
            <div className="content">
                <div className="row">
                    <div className="col-md-4">
                        {isLoggedIn && user?.user.role == '1' && <button style={{ width: "100px" }} onClick={() => window.location.href = "/home"} className='btn-back'>{t('Back')}</button>}
                    </div>
                    <div className="col-md-4">
                        <div className='row main-logo'>
                            <img src={Logo} alt="" />
                            {isLoggedIn ?
                                <p className='title' style={{ textAlign: 'center', fontSize: '30px', fontWeight: 'bold', color: 'black' }}>{t('Home.GiveToWorld')}</p>
                                :
                                <p className='title' style={{ textAlign: 'center', fontSize: '30px', fontWeight: 'bold', color: 'black' }}>{t('Home.Title')}</p>
                            }
                        </div>
                    </div>
                </div>
                {isLoggedIn && user?.user.role == '1'
                    &&
                    < div className='row' style={{ textAlign: 'center' }}>
                        < div className='col-md-4'>
                            <button onClick={() => { window.location.href = "/home" }} className='btn-green' style={{ width: '70%' }}>ALL ROOM</button>
                        </div>
                        <div className='col-md-4' >
                            <button onClick={() => { window.location.href = "/friendandyour" }} className='btn-green' style={{ width: '70%' }}>FRIENDS AND YOUR</button>
                            <div style={{ display: 'flex', justifyContent: 'center', height: 30, alignItems: 'center' }}>
                                <p style={{ width: 40, height: 2, backgroundColor: 'black' }}></p>
                            </div>
                        </div>
                        <div className='col-md-4' >
                            <button onClick={() => { window.location.href = "/update-profile" }} className='btn-green' style={{ width: '70%' }}>{t('Home.Account')}</button>
                        </div>
                    </div>
                }
                {hasSubscription ?
                    <div className='row cards' style={{ justifyContent: 'center' }}>
                        {users?.length > 0 && users?.map((item, index) =>
                            <div key={index}>
                                <div className='row' style={{ fontWeight: 'bold' }}>
                                    <div className='col-md-12' style={{ display: 'flex' }}>
                                        <div className='col-md-3'>
                                            <div style={{ borderStyle: 'solid', borderWidth: 4, borderRadius: 20, textAlign: 'center', padding: 3, margin: 5 }}>
                                                <label>{regions[item.theme].name}</label>
                                            </div>
                                        </div>
                                        <div className='col-md-3'>
                                            <div style={{ borderStyle: 'solid', borderRadius: 20, backgroundColor: 'black', textAlign: 'center', padding: 3, margin: 5 }}>
                                                <label style={{ color: 'white' }}>{item.user?.youtube_url ? item.user?.youtube_url : 'MY ARTISTIC STORE'}</label>
                                            </div>
                                        </div>
                                        <div className='col-md-3'>
                                            <div style={{ textAlign: 'center', padding: 3, margin: 5 }}>
                                                <label>{item?.is_friend ? 'FRIEND' : 'YOUR'}</label>
                                            </div>
                                        </div>
                                        <div className='col-md-3'>
                                            <div style={{ borderStyle: 'solid', borderRadius: 20, backgroundColor: 'black', textAlign: 'center', padding: 3, margin: 5 }}>
                                                <label style={{ color: 'white', fontSize: 12, marginRight: 5 }}>NUMBER OF FRIENDS</label>
                                                <label style={{ backgroundColor: 'white', color: 'black', borderRadius: 20, width: '35%', fontSize: 12 }}>{item.friend_count}</label>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className='row mb-2'>
                                    <div className='col-md-12' style={{ borderStyle: 'solid', borderRadius: 50, backgroundColor: 'black', display: 'grid', alignItems: 'center' }}>
                                        <div className='row'>
                                            <div className='col-md-2' style={{ textAlign: 'center' }}>
                                                <label style={{ color: 'white', fontWeight: 'bold', textAlign: 'center', fontSize: 12 }}>{item.user.username}</label>
                                            </div>
                                            <div className='col-md-8' style={{ textAlign: 'end', marginBottom: 0 }}>
                                                <label style={{ color: 'white', fontSize: 10 }}>ONLINE 1 HOUR</label>
                                            </div>
                                            <div className='col-md-2'>
                                            </div>
                                        </div>
                                        <div className='row mb-1'>
                                            <div className='col-md-2'>
                                                <p style={{ color: 'white', fontWeight: 'bold', textAlign: 'center', marginBottom: 0, fontSize: 25 }}>I GIVE</p>
                                            </div>
                                            <div className='col-md-8' style={{ borderStyle: 'solid', borderRadius: 50, backgroundColor: 'white', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                                                <label>{item?.message}</label>
                                            </div>
                                            <div className='col-md-2' style={{ textAlign: 'center' }}>
                                                {item.is_friend &&
                                                    <button onClick={() => onClickUpdateFriend('delete')} style={{ backgroundColor: 'white', color: 'black', width: '60%', fontWeight: 'bold' }}>DELETE FRIEND</button>
                                                }
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>)
                        }

                        {isLoggedIn && user?.user.role == '1' && users?.length == 0 &&
                            <div style={{ height: '500px', alignItems: 'center', justifyContent: 'center', display: 'flex' }}>
                                <p className='description'>{t('Home.NoProfile')}</p>
                            </div>
                        }
                        {(!isLoggedIn || user?.user.role != '1') &&
                            <div style={{ height: '500px', alignItems: 'center', justifyContent: 'center', display: 'flex' }}>
                                <p className='description'>{t('Home.OurAccessible')}</p>
                            </div>
                        }
                    </div>
                    :
                    <Subscription></Subscription>
                }
            </div >
            {hasSubscription ?
                <div className='content-send'>
                    <div className='row' style={{ marginTop: 10 }}>
                        <div className='col-md-2'>
                        </div>
                        <div className='col-md-1' style={{ textAlign: 'center' }}>
                            <label style={{ color: 'white', fontWeight: 'bold', textAlign: 'center', fontSize: 10, marginTop: 20 }}>320/{giveMessage.length}</label>
                        </div>
                        <div className='col-md-4'>
                            <button style={{ backgroundColor: 'white', color: 'black', fontWeight: 'bold', paddingTop: 4, paddingBottom: 4, width: '-webkit-fill-available' }}>RELOAD PAGE FOR DISPLAY NEW POSTED</button>
                        </div>
                        <div className='col-md-4' style={{ textAlign: 'end' }}>
                            <label style={{ color: 'white', paddingRight: 5 }}>THEME</label>
                            <div style={{ backgroundColor: 'white', display: 'inline-flex', borderRadius: 50 }}>
                                <select value={selectedTheme} onChange={(e) => onClickTheme(e.target.value)} className='select-region'>
                                    {regions && regions.map((item, index) => <option value={item.id} key={item + index}>{item.name}</option>)}
                                </select>
                            </div>
                        </div>
                    </div>
                    <div className='row'>
                        <div className='col-md-2'>
                            <p style={{ color: 'white', fontWeight: 'bold', textAlign: 'center', marginBottom: 0, fontSize: 25 }}>I GIVE</p>
                        </div>
                        <div className='col-md-8' style={{ borderStyle: 'solid', borderRadius: 50, backgroundColor: 'white' }}>
                            <input onChange={(e) => handleChange(e)} maxLength={320} className='input-message'></input>
                        </div>
                        <div className='col-md-2' style={{ textAlign: 'center' }}>
                            <button onClick={() => openModal()} style={{ backgroundColor: 'white', color: 'black', width: '60%', fontWeight: 'bold' }}>SEND</button>
                        </div>
                    </div>
                </div>
                :
                <div className='copyright'>
                    <a>GIVEPARK copyright 2024</a>
                    <a style={{ cursor: 'pointer', marginBottom: '10px' }} onClick={() => window.location.href = "/conditions"}>General Terms And Conditions</a>
                </div>
            }

            <Modal
                isOpen={modalIsOpen}
                onAfterOpen={afterOpenModal}
                onRequestClose={closeModal}
                style={customStyles}
                contentLabel="Example Modal"
            >
                <p className='modal-content'>{t('Home.SendModalTitle')}</p>
                <div style={{ display: 'flex', justifyContent: 'end' }}>
                    <button className='modal-btn-send btn-send' onClick={() => onClickedSendMessage(customerEmail)}>{t('Home.SendModalSend')}</button>
                    <button className='modal-btn-send btn-send' onClick={closeModal}>{t('Home.SendModalNo')}</button>
                </div>
            </Modal>
        </>
    )
}

export default FriendAndYour;
