import {
  REGISTER_SUCCESS,
  REGISTER_FAIL,
  LOGIN_SUCCESS,
  LOGIN_FAIL,
  SENT_CODE,
  LOGOUT,
  SET_MESSAGE,
  GET_FRIENDS,
  NOTIFICATION_REVIEWED,
} from "./types";

import AuthService from "../services/auth.service";
import { MessageType } from "../constants/message";
import notificationService from "../services/notification.service";


export const register = (formData) => (dispatch) => {
  return AuthService.register(formData).then(
    (result) => {
      const { data, status, message } = result;
      if (status == 200) {
        if (data.access_token) {
          localStorage.setItem("user", JSON.stringify(data));
        }
        dispatch({
          type: LOGIN_SUCCESS,
          payload: { user: data },
        });
        dispatch({
          type: SET_MESSAGE,
          payload: { message: MessageType.SUCCESS_REGISTER, type: 'success' },
        });
      } else {
        dispatch({
          type: SET_MESSAGE,
          payload: { message: message, type: 'warning' },
        });
      }
      return Promise.resolve();
    },
    (error) => {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();

      dispatch({
        type: REGISTER_FAIL,
      });

      dispatch({
        type: SET_MESSAGE,
        payload: { message: message, type: 'warning' },
      });

      return Promise.reject();
    }
  );
};

export const login = (email, password) => (dispatch) => {
  return AuthService.login(email, password).then(
    async (result) => {
      const { data, status, message } = result;
      if (status == 200) {
        if (data.access_token) {
          localStorage.setItem("user", JSON.stringify(data));
        }
        dispatch({
          type: SET_MESSAGE,
          payload: { message: MessageType.SUCCESS_LOGIN, type: 'success' },
        });
        setInterval(() => {
          dispatch({
            type: LOGIN_SUCCESS,
            payload: { user: data },
          });
        }, 1200);
      } else if (status == 401) {
        dispatch({
          type: SET_MESSAGE,
          payload: { message: MessageType.WARNING_NOT_EXIST_ACCOUNT, type: 'warning' },
        });
      } else if (status == 402) {
        dispatch({
          type: SET_MESSAGE,
          payload: { message: MessageType.WARNING_BLOCKED_ACCOUNT, type: 'warning' },
        });
      } else if (status == 403) {
        dispatch({
          type: SET_MESSAGE,
          payload: { message: MessageType.WARNING_WRONG_PASSWORD, type: 'warning' },
        });
      } else {
        dispatch({
          type: SET_MESSAGE,
          payload: { message: message, type: 'warning' },
        });
      }

      return Promise.resolve();
    },
    (error) => {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();

      dispatch({
        type: LOGIN_FAIL,
      });

      dispatch({
        type: SET_MESSAGE,
        payload: { message: message, type: 'warning' },
      });

      return Promise.reject();
    }
  );
};

export const adminLogin = (email, password) => (dispatch) => {
  return AuthService.adminLogin(email, password).then(
    async (result) => {
      const { data, status, message } = result;
      if (status == 200) {
        if (data.access_token) {
          localStorage.setItem("user", JSON.stringify(data));
        }
        dispatch({
          type: SET_MESSAGE,
          payload: { message: MessageType.SUCCESS_LOGIN, type: 'success' },
        });
        setInterval(() => {
          dispatch({
            type: LOGIN_SUCCESS,
            payload: { user: data },
          });
        }, 1200);
      } else if (status == 401) {
        dispatch({
          type: SET_MESSAGE,
          payload: { message: MessageType.WARNING_NOT_EXIST_ACCOUNT, type: 'warning' },
        });
      } else if (status == 402) {
        dispatch({
          type: SET_MESSAGE,
          payload: { message: MessageType.WARNING_BLOCKED_ACCOUNT, type: 'warning' },
        });
      } else if (status == 403) {
        dispatch({
          type: SET_MESSAGE,
          payload: { message: MessageType.WARNING_WRONG_PASSWORD, type: 'warning' },
        });
      } else {
        dispatch({
          type: SET_MESSAGE,
          payload: { message: message, type: 'warning' },
        });
      }

      return Promise.resolve();
    },
    (error) => {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();

      dispatch({
        type: LOGIN_FAIL,
      });

      dispatch({
        type: SET_MESSAGE,
        payload: { message: message, type: 'warning' },
      });

      return Promise.reject();
    }
  );
};

export const reset_password = (email) => (dispatch) => {
  return AuthService.reset_password(email).then(
    (result) => {
      const { data, status, message } = result;
      if (status == 200) {
        dispatch({
          type: SET_MESSAGE,
          payload: { message: MessageType.SUCCESS_RESET_PASS, type: 'warning' },
        });
      } else {
        dispatch({
          type: SET_MESSAGE,
          payload: { message: message, type: 'warning' },
        });
      }

      return Promise.resolve();
    },
    (error) => {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();

      dispatch({
        type: LOGIN_FAIL,
      });

      dispatch({
        type: SET_MESSAGE,
        payload: { message: message, type: 'warning' },
      });

      return Promise.reject();
    }
  );
};

export const updateNotificationState = (data) => (dispatch) => {
  return notificationService.UpdateState(data).then(
    (result) => {
      const { data, status, message } = result;
      console.log(data)
      if (status == 200) {
        dispatch({
          type: NOTIFICATION_REVIEWED,
          payload: data.data.user,
        });
      } 
      return Promise.resolve();
    },
    (error) => {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return Promise.reject();
    }
  )
}


export const sendCode = (email) => (dispatch) => {
  return AuthService.sendCode(email).then(
    (result) => {
      const { data, status, message } = result;
      if (status == 200) {
        dispatch({
          type: SENT_CODE,
          payload: { code: data.code },
        });
      } else {
        dispatch({
          type: SET_MESSAGE,
          payload: { message: MessageType.WARNING_EXIST_ACCOUNT, type: 'warning' },
        });
      }
      return Promise.resolve();
    },
    (error) => {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();

      dispatch({
        type: LOGIN_FAIL,
      });

      dispatch({
        type: SET_MESSAGE,
        payload: { message: message, type: 'warning' },
      });

      return Promise.reject();
    }
  );
}

export const getFriends = (data) => (dispatch) => {
  return AuthService.getFriends(data).then(
    (result) => {
      const { data, status, message } = result;
      if (status == 200) {
        dispatch({
          type: GET_FRIENDS,
          payload: { data },
        });
      } else {
        dispatch({
          type: SET_MESSAGE,
          payload: { message: MessageType.WARNING_EXIST_ACCOUNT, type: 'warning' },
        });
      }
      return Promise.resolve();
    },
    (error) => {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();

      dispatch({
        type: LOGIN_FAIL,
      });

      dispatch({
        type: SET_MESSAGE,
        payload: { message: message, type: 'warning' },
      });

      return Promise.reject();
    }
  );
}


export const logout = () => (dispatch) => {
  AuthService.logout();

  dispatch({
    type: LOGOUT,
  });
};
