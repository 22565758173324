import axios from 'axios'
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import storeService from '../../services/store.service';
import { useParams } from 'react-router-dom';
import userService from '../../services/user.service';
import './index.css'
import Advice from '../../component/Advice';
import NewAdvice from '../../component/NewAdvice';

const API_URL = "localhost:8000/api/store/"

const ViewStore = () => {
    const [advices, setAdvices] = useState([])
    const { userId } = useParams();

    const getData = () => {
        storeService.GetAdvices({ id: userId }).then(
            (res) => {
                setAdvices([...res.data.data.advices])
                return Promise.resolve
            }
        )
    }

    useEffect(() => {
        getData()
    }, [])
    return (
        <div className='content' >
            <div style={{ display: 'flex', alignItems: 'center', flexDirection: 'column' }}>
                <p className='title' style={{ textAlign: 'center', fontSize: '22px', fontWeight: 'bold', color: 'black' }}>EACH DANCES MOVEMENTS IN PHASE IN ONE SKETCH DRAWING TO CREATE A UNIQUE 7 MINIMUM AND MORE SEQUENCE DANCE</p>
                <p className='title' style={{ textAlign: 'center', fontSize: '22px', fontWeight: 'bold', color: 'black' }}>SELL DANCES ADVICES POSSIBLE TO REPRODUCE FOR WOMEN AND CHILDREN</p>
                <div style={{ border: '4px solid black', borderRadius: '50px', width: 'fit-content', padding: '4px 32px' }}>
                    <p style={{ margin: '0px', fontWeight: 'bold', textAlign: 'center', fontSize: '14px' }}>The chracter created for your dances advices should be shown to the client so that there is a preview of the drawing style before purchase</p>
                    <p style={{ margin: '0px', fontWeight: 'bold', textAlign: 'center', fontSize: '14px' }}>SHOW AND ASK YOUR FREELANCE DRAWING TO MAKE A DRAWING OF THE CHARACTER IN FIXED POSITION WITHOUT DANCES MOVEMENTS "TO SEND TO THE USER BEFORE SALE"</p>
                </div>
                <p style={{ fontWeight: 'bold', fontSize: '12px' }}>The same character must be used for all your dances</p>
            </div>
            <div className='offer-container' style={{minHeight: `${document.documentElement.clientHeight - 648}px`}}>
                    {
                        advices.map((item, index) => {
                            return (
                                <Advice title={item.advice} id={item._id} refresh={getData} viewMode key={`advice-item-${index}`} />
                            )
                        })
                    }
            </div>
        </div>
    )
}

export default ViewStore;