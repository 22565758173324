export const REGISTER_SUCCESS = "REGISTER_SUCCESS";
export const REGISTER_FAIL = "REGISTER_FAIL";
export const LOGIN_SUCCESS = "LOGIN_SUCCESS";
export const NOTIFICATION_REVIEWED = "NOTIFICATION_REVIEWED";
export const SENT_CODE = "SENT_CODE";
export const LOGIN_FAIL = "LOGIN_FAIL";
export const LOGOUT = "LOGOUT";
export const UPDATE_SUCCSS = "UPDATE_SUCCSS";
export const UPDATE_FAIL = "UPDATE_FAIL";
export const GET_USERS_SUCCESS = "GET_USERS_SUCCESS";
export const GET_USER_FAIL = "GET_USER_FAIL";
export const GET_CUSTOMERS_SUCCESS = "GET_CUSTOMERS_SUCCESS";
export const UPDATE_CUSTOMER_SUCCESS = "UPDATE_CUSTOMER_SUCCESS";
export const GET_CUSTOMERS_FAIL = "GET_CUSTOMERS_FAIL";

export const SET_MESSAGE = "SET_MESSAGE";
export const CLEAR_MESSAGE = "CLEAR_MESSAGE";

export const SET_MAIL_COUNT = "SET_MAIL_COUNT";
export const SET_SUBSTATUS = "SET_SUBSTATUS";

export const GET_FRIENDS = "GET_FRIENDS";