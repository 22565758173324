import React from 'react'
import './index.css'
import notificationService from '../../../services/notification.service';

const notifications = [
    {
        title: "Your account is now active and usable G.I.V.E.P.A.R.K",
        content: "Welcome to G.I.V.E.P.A.R.K community take advantage of our benefits for your life and store."
    },
    {
        title: "Your last research is no longer online after 24 hours G.I.V.E.P.A.R.K",
        content: "Send researches again anytime and gain customers for your store anytime!"
    },
    {
        title: "New user added you as a friend.",
        content: "A user has just added you as a friend from one of your researches."
    },
    {
        title: "Subscription validated for this month G.I.V.E.P.A.R.K",
        content: "Validation of your subscription for this month and automatically renewed every month, add unlimited friends without restriction and managed your store."
    },
    {
        title: "Subscription renewed for this month G.I.V.E.P.A.R.K",
        content: "Your subscription automatically renewed is renewed for this month add unlimited friends without restriction."
    },
    {
        title: "Subscription termination G.I.V.E.P.A.R.K",
        content: "Canceled, your automatic subscription is terminated for next month."
    }
]
const NotificationItem = ({ data , getData}) => {

    const { theme , _id} = data;
    const onClickDelete = async () => {
        const res = await notificationService.DeleteNotification({id:_id})
        if(res == 200)
            getData()
    }
    return <div className='notification-item-container'>
        <div className='notification-item-del'>
            <button onClick={() => {onClickDelete()}}>DELETE</button>
        </div>
        <div className='notification-item'>
            <div className='notification-item-content' >
                <p className='title'>
                    {notifications[theme]?.title}
                </p>
                <p className='content'>
                    {notifications[theme]?.content}
                </p>
                <p>More users and researches expected</p>
                <p>The site also works by word of mouth</p>
                <p>oavlite.com</p>
            </div>
        </div>
    </div>
}

export default NotificationItem;