
import { useNavigate } from 'react-router-dom';
import Logo from '../../assets/svgs/Logo.png';
import './styles.css';
import { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { register, sendCode } from '../../actions/auth';
import { SENT_CODE } from '../../actions/types';
import { OtpInput } from 'reactjs-otp-input';
import validator from "validator";
import { setMessage } from '../../actions/message';

function Register() {
    const navigate = useNavigate();
    const { t, i18n } = useTranslation();
    const [avatar, setAvatar] = useState();
    const { isSent, code, user, isLoggedIn } = useSelector(state => state.auth);
    const interval = useRef();
    const [time, setTime] = useState();
    const [otp, setOtp] = useState('');
    const [isValidEmail, setIsValidEmail] = useState(true);
    const [userInfo, setUserInfo] = useState({
        name: "",
        firstName: "",
        nickname: "",
        email: "",
        password: "",
        confirmPass: "",
    })
    const [errorMsg, setErrorMsg] = useState("");

    useEffect(() => {
        if (isLoggedIn && user?.user.role == '1') {
            window.location.href = "/home";
        }
    }, [isLoggedIn, user])

    useEffect(() => {
        if (isSent) {
            dispatch(setMessage({ message: t('successSent'), type: 'success' }));
        }
        console.log(isSent)
        var iInitTime = 20;
        // clearInterval(interval.current);
        // setTime(iInitTime)
        // if (isSent) {
        //     interval.current = setInterval(() => {
        //         iInitTime--
        //         if (iInitTime == 0) {
        //             dispatch({
        //                 type: SENT_CODE,
        //                 payload: { isSent: false },
        //             });
        //             clearInterval(interval.current);
        //         }
        //         setTime(iInitTime);
        //     }, 1000);
        // }
    }, [isSent])

    useEffect(() => {
        if (otp.length == 6) {
            console.log(otp)
            console.log(code)
            if (otp == code) {
            } else {
            }
        }
    }, [otp])

    const dispatch = useDispatch();
    const handleChange = e => {
        if (e.target.files.length) {
            setAvatar({
                preview: URL.createObjectURL(e.target.files[0]),
                raw: e.target.files[0]
            });
        }
    };
    const handleOtpChange = (otp) => setOtp(otp);

    const onClickSendCode = () => {
        const { email } = userInfo;
        if (email == "") {
            dispatch(setMessage({ message: t('errorValidateFields'), type: 'warning' }));
            return;
        }
        if (!validator.isEmail(email)) {
            dispatch(setMessage({ message: t('errorEmail'), type: 'warning' }));
            return;
        }
        dispatch(sendCode(email))
    }

    const onClickRegister = () => {
        const { name, firstName, email, password, confirmPass, nickname } = userInfo;
        if (name == "" || firstName == "" || password == "" || confirmPass == "" || nickname == "") {
            dispatch(setMessage({ message: t('errorValidateFields'), type: 'warning' }));
            return;
        }
        if (name.length < 3 || name.length > 20) {
            dispatch(setMessage({ message: t('errorValidateName'), type: 'warning' }));
            return;
        }
        if (firstName.length < 3 || firstName.length > 20) {
            dispatch(setMessage({ message: t('errorValidateName'), type: 'warning' }));
            return;
        }
        if (nickname.length < 2 || nickname.length > 15) {
            dispatch(setMessage({ message: t('errorValidateName'), type: 'warning' }));
            return;
        }
        if (password.length < 8) {
            dispatch(setMessage({ message: t('errorValidatePass'), type: 'warning' }));
            return;
        }
        if (password !== confirmPass) {
            dispatch(setMessage({ message: t('errorMatchPass'), type: 'warning' }));
            return;
        }
        // setErrorMsg("");
        const formData = new FormData();
        formData.append("username", name);
        formData.append("firstname", firstName);
        formData.append("nickname", nickname);
        formData.append("email", email);
        formData.append("password", password);
        if (avatar) {
            formData.append("avatar", avatar.raw);
        }
        dispatch(register(formData));
    }
    return (
        <>
            <div className="content"  style={{ display:'block',minHeight:'calc(100vh - 285px)' }}>
                <div className="row register">
                    <div className=''>
                        <div className='row'>
                            <p className='title' style={{ textAlign: 'center', fontSize: '38px', fontWeight: 'bold', color: 'black',  marginBottom: 0 }}>FREE REGISTERATION</p>
                            {/* <p className='title' style={{ textAlign: 'center', fontSize: '13px', fontWeight: 'bold', color: 'black', marginBottom: 20 }}>MAKE YOUR PURCHAGES FOR MEN AND WOMEN WITH UNUSUAL SELLERS</p> */}
                        </div>
                        <div className='row' style={{ marginTop: '10px', justifyContent: 'center', flexDirection:'column', alignItems:'center' }}>
                            <div className='row' style={{ width: '18%', minWidth:"330px" }}>
                                <label className='label-userinfo'>{t('Register.IndicateYourName')}</label>
                                <input maxLength={20} name="first-name" onChange={(e) => setUserInfo(userInfo => ({ ...userInfo, name: e.target.value }))} className='input-userinfo'></input>
                                <label className='label-userinfo'>{t('Register.IndicateYourFirstName')}</label>
                                <input maxLength={20} name="last-name" onChange={(e) => setUserInfo(userInfo => ({ ...userInfo, firstName: e.target.value }))} className='input-userinfo'></input>
                                <label className='label-userinfo'>{t('Register.IndicateYourNickName')}</label>
                                <input maxLength={15} name="nickname" onChange={(e) => setUserInfo(userInfo => ({ ...userInfo, nickname: e.target.value }))} className='input-userinfo'></input>
                                <label className='label-userinfo'>{t('Register.IndicateYourEmail')}</label>
                                <input maxLength={35} name="email" disabled={isSent} onChange={(e) => { setIsValidEmail(true); setUserInfo(userInfo => ({ ...userInfo, email: e.target.value })) }} className='input-userinfo'></input>
                                {/* {!isValidEmail && <label className='warning'>{t('errorEmail')}</label>} */}
                                {/* <button disabled={isSent} className={`btn-send ${isSent && "disabled"}`} onClick={onClickSendCode}>{isSent ? t('Register.Sent') : t('Register.SendCode')}</button>
                                {isSent && <label style={{ color: 'rgb(255, 153, 0)', fontSize: 12 }}>{t('Register.MailCheck')}</label>}
                                <label className='label-userinfo'>{t('Register.ValidateCode')}</label>
                                <OtpInput isDisabled={!isSent} className={"otp-content"} containerStyle={{ borderStyle: "solid", borderWidth: "2px", borderColor: "black", justifyContent: "space-evenly", borderRadius: "20px" }} inputStyle={{ width: '100%', border: "none", borderRadius: "20px" }} value={otp} onChange={handleOtpChange} numInputs={6} /> */}
                                <label className='label-userinfo'>{t('Register.IndicateYourPass')}</label>
                                <input maxLength={20} name="password" type='password' onChange={(e) => setUserInfo(userInfo => ({ ...userInfo, password: e.target.value }))} className='input-userinfo'></input>
                                <label className='label-userinfo'>{t('Register.ConfirmYourPass')}</label>
                                <input maxLength={20} type='password' onChange={(e) => setUserInfo(userInfo => ({ ...userInfo, confirmPass: e.target.value }))} className='input-userinfo'></input>
                                {
                                    errorMsg &&
                                    <div style={{ background: "lightpink", marginTop: "10px", borderRadius: "5px" }}>
                                        <label className='warning'>{errorMsg}</label>
                                    </div>
                                }
                            </div>
                                <button style={{width:'20%', fontWeight:'bold', fontSize:'28px', padding:"0px"}} onClick={onClickRegister} className='btn-register' >{t('Register.ValidateRegistration')}</button>
                            <h4 style={{ fontWeight: 'bold', marginTop:'20px' }}> VALIDATE THE AUTOMATIC SAVE OF YOUR PASSWORD</h4>
                        </div>
                    </div>
                </div>
            </div>
            {/* <div className='copyright'>
                <a>GIVEPARK copyright 2024</a>
                <a style={{ cursor: 'pointer', marginBottom: '10px' }} onClick={() => window.location.href = "/conditions"}>General Terms And Conditions</a>
            </div> */}
        </>
    )
}

export default Register;
