import {
  REGISTER_SUCCESS,
  REGISTER_FAIL,
  LOGIN_SUCCESS,
  SENT_CODE,
  LOGIN_FAIL,
  UPDATE_SUCCSS,
  GET_FRIENDS,
  LOGOUT,
  NOTIFICATION_REVIEWED,
} from "../actions/types";

const user = JSON.parse(localStorage.getItem("user"));

const initialState = user
  ? { isLoggedIn: true, user, isSent: null, code: null, friends: 0, addFriends:0 ,addedFriends:0 }
  : { isLoggedIn: false, user: null, isSent: false, code: null };

export default function (state = initialState, action) {
  const { type, payload } = action;
  console.log(type)
  switch (type) {
    case REGISTER_SUCCESS:
      return {
        ...state,
        isLoggedIn: false,
      };
    case REGISTER_FAIL:
      return {
        ...state,
        isLoggedIn: false,
      };
    case LOGIN_SUCCESS:
      return {
        ...state,
        isLoggedIn: true,
        user: payload.user,
      };
    case UPDATE_SUCCSS:
      return {
        ...state,
        user: payload.user,
      }
    case SENT_CODE:
      return {
        ...state,
        isSent: true,
        code: payload.code
      };
    case LOGIN_FAIL:
      return {
        ...state,
        isLoggedIn: false,
        user: null,
      };
    case LOGOUT:
      return {
        ...state,
        isLoggedIn: false,
        user: null,
      };
    case NOTIFICATION_REVIEWED:
      return {
        ...state,
        user: {...user, user: payload}
      }
    case GET_FRIENDS:
      return {
        ...state,
        friends: payload.data.friends.friendsCount,
        addFriends: payload.data.friends.addFriendsCount,
        addedFriends: payload.data.friends.addedFriendsCount,
      };
    default:
      return state;
  }
}
