import { GET_USERS_SUCCESS, GET_USER_FAIL } from "../actions/types";

const initialState = { users: [] };

export default function (state = initialState, action) {
  const { type, payload } = action;

  switch (type) {
    case GET_USERS_SUCCESS:
      return { users: payload.users };

    case GET_USER_FAIL:
      return state;

    default:
      return state;
  }
}
