
import { useNavigate, useParams } from 'react-router-dom';
import Logo from '../../assets/svgs/Logo.png';
import './styles.css';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { createSubscription, updateExpiredAt, updateProfile } from '../../actions/users';
import { setMessage } from '../../actions/message';
import GivePark from '../../component/GivePark';

function Subscription() {
    const { isLoggedIn, user } = useSelector(state => state.auth);
    const { t, i18n } = useTranslation();
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const Checkout = () => {
        if (isLoggedIn && user?.user.role == '1') {
            const data = {
                id: user?.user._id
            }
            dispatch(createSubscription(data))
        } else {
            dispatch(setMessage({ message: t('Home.AccessiblePage'), type: 'warning' }));
        }
    }
   
    return (
        <div className="content" style={{ paddingTop: '0px', paddingBottom: '10px' }}>
            {/* <div className="row">
                <div className="col-md-3">
                    {isLoggedIn && user?.user.role == '1' && <button style={{ width: "100px" }} onClick={() => window.location.href = "/"} className='btn-back'>{t('Back')}</button>}
                </div>
                <div className="col-md-6">
                    <div className='row main-logo'>
                        <img src={Logo} alt="" />
                        {isLoggedIn ?
                            <p className='title' style={{ textAlign: 'center', fontSize: '20px', fontWeight: 'bold', color: 'black' }}>{t('Home.GiveToWorld')}</p>
                            :
                            <p className='title' style={{ textAlign: 'center', fontSize: '20px', fontWeight: 'bold', color: 'black' }}>{t('Home.Title')}</p>
                        }
                    </div>
                </div>
            </div> */}
            <div style={{ padding: 40 , minHeight: `${document.documentElement.clientHeight - 286}px`}}>
                <div className='row subscription'>
                    <GivePark />
                    <div style={{ display: 'flex', flexDirection: 'column', alignItems: "center" ,marginTop: "20px",}}>
                        <div style={{backgroundColor:'black', borderRadius:'30px', padding:8}}>
                            <div style={{ display: 'flex', flexDirection: 'column', alignItems: "center", backgroundColor:'white', borderRadius:'31px', padding : '0px 50px 10px 50px'}}>
                                <label style={{  fontSize: 30 }}>1 MONTH SUBSCRIPTION</label>
                                <h5 style={{ fontWeight: 'bold' }}>USE PAYPAL TO COMPLETE YOUR TRANSACTIONS AND REQUEST A REFUND</h5>

                                {/* <label style={{ fontSize: '12px', fontWeight: 'bold', marginBottom: "10px", }}>If you stop your subscription your number of friends drops to a maximum of 10 in non-premium and will be restored if subscription</label> */}
                                <div className='btn-subscription btn-green' onClick={() => { Checkout() }}>
                                    <h5 style={{ color: 'white', margin: '0px' }}>INCREASE YOUR NUMBER OF CUSTOMERS</h5>
                                    <p style={{ color: 'white', marginBottom: '0px', fontSize: '9px' }}>Call freelance sketches drawing to create your perfect advice of dance movements under your direction and analysis for your sales and a freelancer to send daily researches</p>
                                    <label style={{ color: 'white', fontSize: '13px' }}>EACH DANCES MOVEMENTS IN PHASE IN ONE SKETCH DRAWING TO CREATE A UNIQUE 7 MINIMUM AND MORE SEQUENCE DANCE  </label>
                                </div>
                                {/* <label style={{ fontSize: '10px', fontWeight: 'bold', marginBottom: "10px", }}>INCREASE THE ATTENTION ON YOUR RESEARCH AND YOUR SELLABLE ADVICES TO EXERCISES BETTER LIVING FOR FIGHT STRESS AND DEPRESSION OR ADDICTION WITH MORE FRIENDS AND MAKE YOUR RESEARCH APPEAR AT THE TOP OF THE LIST</label>
                    <label style={{ fontSize: '10px', fontWeight: 'bold', marginBottom: "10px", }}>Write "COPYRIGHTED + Your nickname" in several lines of text of your written advices and only send images to protect your advice from theft and other misuse</label>
                    <h6 style={{fontWeight:'bold'}}>EXAMPLE</h6> */}
                                <label style={{ fontSize: '8px', fontWeight: 'bold', marginBottom: "0px", marginTop: '5px', }}>INCREASE THE ATTENTION ON YOUR RESEARCHES AND  YOUR STORE AND MAKE YOUR RESEARCHES APPEAR AT THE TOP</label>
                                <label style={{ fontSize: '8px', fontWeight: 'bold', marginBottom: "0px", }}>Saved the date of creation for proof not copyable if several similarities are found then you can file a complaint contact the platform that the seller used</label>
                                <h5 style={{ fontWeight: 'bold', marginBottom: '0px', marginTop: '5px' }}>SELL DANCES ADVICES POSSIBLE TO REPRODUCE FOR WOMEN AND CHILDREN</h5>
                                <label style={{ fontSize: '16px' }}>BENEFIT THE BEST DANCES ADVICES ONLY CREATED TO WIN BEST FANS </label>
                            </div>
                        </div>
                    </div>

                </div>
                <div className='row subscription' style={{ justifyContent: "center" }}>
                    <div style={{ justifyContent: "center", alignItems: "center", display: "contents" }}>
                        <div style={{ alignItems: "center", display: "flex", justifyContent: "center", marginTop: 50, borderRadius: "50px", width:'900px', height: "300px", backgroundColor: "black" }}>
                            <label style={{ color: 'white', fontSize: 100 }}>69$</label>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Subscription;
