
import { useNavigate, useParams } from 'react-router-dom';
function Conditions() {
    return (
        <>
            <iframe src="/Conditions.pdf" width="100%" height="100%" />
        </>
    )
}

export default Conditions;
