import axios from 'axios'
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import storeService from '../../services/store.service';
import userService from '../../services/user.service';
import './index.css'
import Advice from '../../component/Advice';
import NewAdvice from '../../component/NewAdvice';
import { SET_MESSAGE } from '../../actions/types';

const API_URL = "localhost:8000/api/store/"

const NewStore = () => {
    const [title, setTitle] = useState("")
    const [isAddNew, setIsAddNew] = useState(false)
    const [addNewContent, setAddNewContent] = useState("ADD NEW ADVICE")
    const { isLoggedIn, user } = useSelector(state => state.auth);
    const { substatus } = useSelector(state => state.substatus)
    const [advice, setAdvice] = useState(null)
    const dispatch = useDispatch()


    const getData = () => {
        storeService.GetAdvices({ id: user.user._id }).then(
            (res) => {
                setTitle(res.data.data.advices[0]?.advice)
                return Promise.resolve
            }
        )
    }

    const handleValidateClick = () => {
        if (title == "")
            return

        const data = { title: title, user: user.user._id }
        console.log({ data })
        storeService.CreateAdvice({ title: title, user: user.user._id }).then(
            (res) => {
                console.log(res)
                getData()
                dispatch({
                    type: SET_MESSAGE,
                    payload: { message: "Store created successfully", type: 'success' },
                })
                return Promise.resolve
            }
        )
        setIsAddNew(false)
        toggleAddNew()
    }

    const toggleAddNew = () => {
        setIsAddNew(!isAddNew)
    }
    useEffect(() => {
        getData()
    }, [])
    return (
        <div className='content' style={{ minHeight: '54%' }}>
            <div style={{ display: 'flex', alignItems: 'center', flexDirection: 'column' }}>
                <p className='title' style={{ textAlign: 'center', fontSize: '22px', fontWeight: 'bold', color: 'black' }}>CREATE A WORK TEAM</p>
                <p className='title' style={{ textAlign: 'center', fontSize: '12px', fontWeight: 'bold', color: 'black', marginBottom: '0px' }}>SET UP A PAYMENT LINKED TO YOUR DANCES ADVICES FOR LESS COSTS AND SEND THE LINK TO EACH OF YOUR CUSTOMERS FOR SECURE PAYMENT BY PADLOCK AND REFUNDABLE IF PREJUDICIAL</p>
                <p className='title' style={{ textAlign: 'center', fontSize: '22px', fontWeight: 'bold', color: 'black' }}>SELL DANCES ADVICES POSSIBLE TO REPRODUCE FOR WOMEN AND CHILDREN</p>
                <div style={{ border: '4px solid black', borderRadius: '50px', width: 'fit-content', padding: '4px 32px' }}>
                    <p style={{ margin: '0px', fontWeight: 'bold', textAlign: 'center', fontSize: '14px' }}>The chracter created for your dances advices should be shown to the client so that there is a preview of the drawing style before purchase</p>
                    <p style={{ margin: '0px', fontWeight: 'bold', textAlign: 'center', fontSize: '12px' }}>SHOW AND ASK YOUR FREELANCE DRAWING TO MAKE A DRAWING OF THE CHARACTER IN FIXED POSITION WITHOUT DANCES MOVEMENTS "TO SEND TO THE USER BEFORE SALE"</p>
                </div>
                <p style={{ fontWeight: 'bold', fontSize: '12px' }}>The same character must be used for all your dances</p>
            </div>
            <div className='offer-container' style={{ minHeight: `${document.documentElement.clientHeight - 648}px` }}>
                <NewAdvice setTitle={setTitle} handleValidateClick={handleValidateClick} title={title} />
            </div>
        </div>
    )
}

export default NewStore;