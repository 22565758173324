import React from 'react'
import './index.css'
const NewAdvice = ({ setTitle, handleValidateClick , title}) => {
    return (
        <div>
            <div className='title-form-container'>
                <div style={{ width: '100%' }}>
                    <p style={{ margin: "0px", fontSize: '9px' }}>INDICATE THE TITLE OF THE DANCE MOVEMENTS ADVICE OR YOUR FREELANCE SERVICE</p>
                    <div style={{ display: 'flex' }}>
                        <input type='text' style={{ border: '2px solid black', borderRadius: '30px', width: 'calc(100% - 100px)', fontSize: '16px', padding: '0px 15px' }} onChange={(e) => { setTitle(e.target.value) }} value={title} />
                        <button onClick={() => { handleValidateClick() }} style={{ background: 'black', width: '100px', color: 'white', borderRadius: '30px', padding: '3px 10px', fontSize: '12px', height: '30px' }}>VALIDATE</button>
                    </div>
                </div>
            </div>
            <div className='advice-container'>
                <div className='advice-title' style={{height:'66px'}}>
                    <p style={{ fontSize: '24px', margin: '0px', color: 'white' }}>
                        {title ? title : ''}
                    </p>
                    {/* <p style={{ fontSize: '12px', color: 'white', margin: '0px' }}>CONTACT FOR THIS OFFER</p> */}
                </div>
            </div>
        </div>

    )
}

export default NewAdvice;