
import { useNavigate } from 'react-router-dom';
import Logo from '../../assets/svgs/Logo.png';
import './styles.css';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { updateProfile, getSubStatus } from '../../actions/users';
import { setMessage } from '../../actions/message';
import { GiveTheme } from '../../constants/en';
import GivePark from '../../component/GivePark';
import EclipseForm from '../../component/EclipseForm';
import { getFriends, updateNotificationState } from '../../actions/auth';
import { Tooltip } from 'react-tooltip';

function UpdateProfile() {
    const navigate = useNavigate();
    const { user, friends, addFriends, addedFriends } = useSelector(state => state.auth);
    const substatus = useSelector(state => state.substatus);
    const { t, i18n } = useTranslation();
    const dispatch = useDispatch();
    const [errorMsg, setErrorMsg] = useState("");
    const [channel, setChannel] = useState(user.user?.youtube_url ? user.user.youtube_url : "");
    const [selectedTheme, setSelectedTheme] = useState(0);
    const regions = GiveTheme;
    console.log("subStatus", user)
    const [userInfo, setUserInfo] = useState({
        name: user.user.username,
        name: user.user.username,
        firstname: user.user.firstname,
        email: user.user.email,
        image_http: user.user.image_http,
        password: "",
        confirmPass: "",
        nickname: user.user.nickname
    })

    console.log(friends, addFriends, addedFriends)

    const onClickTheme = (value) => {
        setSelectedTheme(value)
    }

    const onClickUpdate = () => {
        const { name, firstname, email, password, confirmPass, nickname } = userInfo;
        if (name == "" || firstname == "" || password == "" || confirmPass == "" || nickname == "") {
            dispatch(setMessage({ message: t('errorValidateFields'), type: 'warning' }));
            return;
        }
        if (password.length < 8) {
            dispatch(setMessage({ message: t('errorValidatePass'), type: 'warning' }));
            return;
        }
        if (password !== confirmPass) {
            dispatch(setMessage({ message: t('errorMatchPass'), type: 'warning' }));
            return;
        }
        const formData = new FormData();
        formData.append("username", name);
        formData.append("firstname", firstname);
        formData.append("email", email);
        formData.append("password", password);
        formData.append("nickname", nickname);
        dispatch(updateProfile(formData));
    }

    const onClickAddStore = () => {
        navigate('/notifications')


    }

    useEffect(() => {
        dispatch(updateNotificationState({user: user.user._id, isUpdate: false}))
        dispatch(getSubStatus({ id: user.user._id }))
        dispatch(getFriends({ email: user.user.email }))
    }, [])


    return (
        <>
            <div className="content" style={{marginTop:'50px'}}>
                {/* <div className="row">
                    <div className="col-md-3">
                        {isLoggedIn && user?.user.role == '1' && <button style={{ width: "100px" }} onClick={() => window.location.href = "/"} className='btn-back'>{t('Back')}</button>}
                    </div>
                    <div className="col-md-6">
                        <div className='row main-logo'>
                            <img src={Logo} alt="" />
                            {isLoggedIn ?
                                <p className='title' style={{ textAlign: 'center', fontSize: '20px', fontWeight: 'bold', color: 'black' }}>{t('Home.GiveToWorld')}</p>
                                :
                                <p className='title' style={{ textAlign: 'center', fontSize: '20px', fontWeight: 'bold', color: 'black' }}>{t('Home.Title')}</p>
                            }
                        </div>
                    </div>
                </div> */}
                <div style={{ display: 'flex', justifyContent: 'center', marginBottom: '20px' , marginTop:'50px'}}>
                    <GivePark />
                </div>
                <div className="row profile">
                    <div className='sub-view' style={{ width: '100%' }}>
                        <div className='row' style={{ marginTop: '10px', justifyContent: 'center', flexDirection: 'column', alignItems: 'center' }}>
                            <EclipseForm title="SUBSCRIPTION DAY REMAINING" value={substatus.hasSubscription ? substatus.remainingDays : "NO SUBSCRIPTION"} />
                            <EclipseForm title="NUMBER OF FREINDS ADDED" value={addedFriends} />
                            <EclipseForm title="NUMBER OF FRIENDS" value={friends} />

                            {/* <input value={channel} onChange={(e) => setChannel(e.target.value)} className='input-userinfo'></input> */}
                            {/* <div  data-tooltip-id={`tooltip-last-research`} style={{ backgroundColor: 'white', display: 'inline-flex', borderRadius: 50, width: '380px', border:'1px solid black', height:'40px' }}>

                                <select value={selectedTheme} openOnClick  className='select-region' style={{ width: '100%' }}>
                                    {regions && regions.map((item, index) => <option value={item.id} key={item + index}>{item.name}</option>)}
                                </select>
                            </div>
                            <Tooltip
                                id={`tooltip-last-research`}

                                className="custom-tooltip"
                                arrowClassName="custom-tooltip-arrow"
                                border={"1px solid black"}
                                noArrow
                                opacity={1}
                                style={{ borderRadius: '30px', backgroundColor: 'white' }}
                                place='bottom-end'
                            >
                                <div>
                                    <p style={{ margin: '0' }}>{item.user?.youtube_url}</p>
                                </div>
                            </Tooltip> */}
                            {user.user?.have_news && <div style={{ display: 'flex', justifyContent: 'end', width: '280px' }}>
                                <span style={{ fontWeight: 'bolder' }}>NEW</span>
                            </div>}
                            <button style={{ width: '300px' }} onClick={onClickAddStore} className='btn-change mt-1 mb-2'>NOTIFICATIONS</button>
                            <div className='row' style={{ width: '300px' }}>
                                {/* <input type="file" accept="image/*" onChange={() => {}} id="actual-btn" hidden /> */}
                                <label className='label-userinfo'>{t('UpdateProfile.Name')}</label>
                                <input disabled value={userInfo.name} onChange={(e) => setUserInfo(userInfo => ({ ...userInfo, name: e.target.value }))} className='input-userinfo'></input>
                                <label className='label-userinfo'>{t('UpdateProfile.FirstName')}</label>
                                <input disabled value={userInfo.firstname} onChange={(e) => setUserInfo(userInfo => ({ ...userInfo, firstname: e.target.value }))} className='input-userinfo'></input>
                                <label className='label-userinfo'>{t('UpdateProfile.NICKNAME')}</label>
                                <input disabled value={userInfo.nickname} onChange={(e) => setUserInfo(userInfo => ({ ...userInfo, nickname: e.target.value }))} className='input-userinfo'></input>
                                <label className='label-userinfo'>{t('UpdateProfile.Email')}</label>
                                <input disabled value={userInfo.email} onChange={(e) => setUserInfo(userInfo => ({ ...userInfo, email: e.target.value }))} className='input-userinfo'></input>
                                <label className='label-userinfo'>{t('UpdateProfile.IndicateCurrPass')}</label>
                                <input maxLength={20} type='password' value={userInfo.password} onChange={(e) => setUserInfo(userInfo => ({ ...userInfo, password: e.target.value }))} className='input-userinfo'></input>
                                <label className='label-userinfo'>{t('UpdateProfile.ConfirmYourPass')}</label>
                                <input maxLength={20} type='password' value={userInfo.confirmPass} onChange={(e) => setUserInfo(userInfo => ({ ...userInfo, confirmPass: e.target.value }))} className='input-userinfo'></input>
                                {
                                    errorMsg &&
                                    <div style={{ background: "lightpink", marginTop: "10px", borderRadius: "5px" }}>
                                        <label className='warning'>{errorMsg}</label>
                                    </div>
                                }
                                <button onClick={onClickUpdate} className='btn-change'>{t('UpdateProfile.ChangePass')}</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default UpdateProfile;
