import React from 'react'
import './index.css'
const Example = () => {
    return (
        <div className='example-container'>
            <div className='left-side-container'>
                EXAMPLE
            </div>
            <div className='right-side-container'>
                A human being is 32 teeth 8 incisors 4 canines 8 premolars 12 molars and 4 wisdom teeth
            </div>
        </div>
    )
}

export default Example;