
import { useNavigate } from 'react-router-dom';
import Logo from '../../assets/svgs/Logo.png';
import './styles.css';
import { useTranslation } from 'react-i18next';
import { useEffect, useState } from 'react';
import validator from "validator";
import { useDispatch, useSelector } from 'react-redux';
import { login, reset_password } from '../../actions/auth';

function Login() {
    const navigate = useNavigate();
    const { t, i18n } = useTranslation();
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [reqEmail, setReqEmail] = useState("");
    const [isValidEmail, setIsValidEmail] = useState(true);
    const [isValidPass, setIsValidPass] = useState(true);
    const dispatch = useDispatch();
    const { isLoggedIn, user } = useSelector(state => state.auth);

    const onLogin = () => {
        dispatch(login(email, password))
    }

    useEffect(() => {
        if (isLoggedIn && user?.user.role == '1') {
            navigate("/home");
        }
    }, [isLoggedIn, user])

    const sendEmail = () => {
        dispatch(reset_password(reqEmail));
    }

    return (
        <>
            <div className="content" style={{ display:'block',minHeight:'calc(100vh - 285px)' }}>
                <div className="row login">
                    <div className='sub-view'>
                        
                        <div className='row'>
                            <label className='label-email'>{t('Login.Email')}</label>
                            <input maxLength={35} value={email} onChange={(e) => { setEmail(e.target.value) }} name="email" type="email" className='input-email'></input>
                            {!isValidEmail && <label className='warning'>{t('errorEmail')}</label>}
                            <label className='label-password'>{t('Login.Password')}</label>
                            <input maxLength={20} value={password} onChange={(e) => { setPassword(e.target.value); }} password="password" type="password" className='input-pass'></input>
                            {!isValidPass && <label className='warning'>{t('errorPass')}</label>}
                            <button className='btn-login' onClick={onLogin}>{t('Login.Login')}</button>
                            {/* <label className='label-forgot-password'>{t('Login.ForgotPass')}</label>
                            <label className='label-required'>{t('Login.EmailRequired')}</label>
                            <input onChange={(e) => setReqEmail(e.target.value)} type='email' className='input-email'></input>
                            <button className='btn-send' onClick={sendEmail}>{t('Login.Send')}</button> */}
                        </div>
                    </div>
                </div>
            </div>
           
        </>
    )
}

export default Login;
